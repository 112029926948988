export const IS_PRODUCTION = process.env.NODE_ENV === `production`

export const rootUrl = window.location.origin

export const serverUrl = IS_PRODUCTION ? "https://us-central1-portosaurus-cf425.cloudfunctions.net/api" : `http://localhost:${process.env.REACT_APP_SERVER_PORT}/portosaurus-cf425/us-central1/api`

export const firebaseConsts = {
  usersCollection: "users"
}
export const config = {
  pollRateMillisPortfolioBuild: 3000, //rate in ms at which to ping the server to see if the build job completed
  timeoutMillisPortfolioBuild: 600000, //10 mins
  timeMillisForTypingToBeConsideredStopped: 500
}

export const portfolioSections: PortfolioSection[] = [
  // "bio" //weird to let them put this lower
  "experience", "education", "projects", "awards", "skills"
]

export const mobileWidth = 1000 //TODO: - get from css variables dtf

export const endpoints = {
  dashboard: "dashboard",
  profile: "profile",
  privacyPolicy: "privacy-policy",
  termsOfService: "terms-of-service",
  login: "login",
  logout: "logout",
  faq: "faq"
}

export const portfolioHostingIdText = "portfolio name"
export const portfolioHostingIdTextFirstCharUpper = portfolioHostingIdText[0].toUpperCase() + portfolioHostingIdText.slice(1)

export const currentYear = (new Date()).getFullYear()

export const portfolioBuildTimeoutSecs = 1800