import React, { useState } from 'react'
import { ItemModProp, NextImageDownloadUrlAndCleanup, getNextImageDownloadUrlAndCleanup, getImageCleanUp, NextImageType } from '../common'
import { DiscardChangesButton, SaveChangesButton, DeleteButton } from '../Subcomponents/EditButtons'
import * as yup from 'yup'
import { Modal, message } from 'antd'
import { Formik } from 'formik'
import { Form, Col } from 'react-bootstrap'
import { monthYearLessThan, intMonthToFullMonthMap, yupYearValidator } from '../../../../utils/datetime'
import { removeUndefined } from '../../../../utils/firebase/profile'
import { currentYear } from '../../../../consts'
import { ImageUpload } from '../Subcomponents/ImageUpload'
import { id, itemName } from './Projects'

export const AddEditDeleteModal = (props: { itemModProp: ItemModProp<Project>; show: boolean, uid: string }) => {
  const { show, itemModProp, uid } = props
  const { item, onOk, onCancel, mode, index, onDelete } = itemModProp
  const addOrEdit = mode === `add` ? `Add` : `Edit`
  const [disabled, setDisabled] = useState(false)
  const [nextProjectImg, setNextProjectImg] = useState<NextImageType>({
    file: undefined,
    update: false
  })
  const schema = yup.object({
    title: yup.string()
      .max(128, `Must be 128 characters or fewer`)
      .required(`Required`),
    dateRange: yup.object({
      monthFrom: yup.string()
        .oneOf(Object.keys(intMonthToFullMonthMap))
        .required(`Required`),
      yearFrom: yupYearValidator
        .required(`Required`),
      toPresent: yup.boolean(),
      monthTo: yup.string()
        .oneOf(Object.keys(intMonthToFullMonthMap))
        .when(`toPresent`, {
          is: false,
          then: (fieldSchema: yup.StringSchema<string>) =>
            fieldSchema
              .required(`Required`)
              .test(`date-test`, `End date cannot be before start date`, function () {
                const { monthFrom, yearFrom, monthTo, yearTo } = this.parent
                return (monthFrom === monthTo && yearFrom === yearTo)
                  || monthYearLessThan(
                    { sMonth: monthFrom, year: yearFrom },
                    { sMonth: monthTo, year: yearTo })
              })
        }),
      yearTo: yupYearValidator
        .when(`toPresent`, {
          is: false,
          then: (fieldSchema: yup.StringSchema<string>) => fieldSchema.required(`Required`)
        })
    }),
    embedVideo: yup.boolean(),
    projectVidUrl: yup.string().url(`Please enter a valid URL, e.g. https://www.example.com`),
    projectImg: yup.mixed(),
    description: yup.string()
      .max(4096, `Must be 4096 characters or fewer`),
    url: yup.string()
      .max(256, `Must be 256 characters or fewer`)
      .url(`Please enter a valid URL, e.g. https://www.example.com`),
  })


  type FormType = {
    title: string;
    dateRange: DateRange
    description: string;
    url?: string;
    embedVideo: boolean;
    projectImg?: DownloadUrl;
    projectVidUrl?: string;
  }

  const toPresent = item.dateRange.toPresent

  const initialValues: FormType = {
    title: item.title,
    dateRange: {
      monthFrom: item.dateRange.monthFrom,
      yearFrom: item.dateRange.yearFrom,
      toPresent: toPresent,
      monthTo: toPresent ? 0 : item.dateRange.monthTo,
      yearTo: toPresent ? currentYear : item.dateRange.yearTo
    },
    projectVidUrl: item.projectVidUrl ?? ``,
    embedVideo: item.projectVidUrl ? true : false,
    description: item.description ?? "",
    url: item.url,
    projectImg: item.projectImg
  }

  const toActualTypeAndCleanups = async (_vals: FormType): Promise<{ project: Project, cleanups: (() => Promise<void>)[] }> => {

    let cleanups: (() => Promise<void>)[] = []

    const { embedVideo, ...vals } = _vals

    /// if we embed video, force delete the old image
    const nextProjectImgGot = embedVideo ? { file: undefined, update: true } : nextProjectImg

    const nextProjectImgDownloadUrlAndCleanup: NextImageDownloadUrlAndCleanup = await getNextImageDownloadUrlAndCleanup(id, vals.projectImg, nextProjectImgGot, uid)
    const projectImg = nextProjectImgDownloadUrlAndCleanup.downloadUrl
    if (nextProjectImgDownloadUrlAndCleanup.cleanup) {
      cleanups.push(nextProjectImgDownloadUrlAndCleanup.cleanup)
    }

    const _project = {
      ...vals,
      /// if not embed video, make undefined the projectVidUrl
      projectVidUrl: embedVideo ? vals.projectVidUrl : undefined,
      projectImg
    }

    const project = (await removeUndefined(_project)) as Project

    return {
      project,
      cleanups
    }
  }
  return (
    <Modal
      title={`${addOrEdit} ${itemName}`}
      visible={show}
      centered
      footer={null}
      onCancel={onCancel}
      maskClosable={false}
      keyboard={false}
      closable={false}
      zIndex={1021}
      destroyOnClose
    >
      <Formik
        validationSchema={schema}
        onSubmit={async (vals, { resetForm }) => {
          setDisabled(true)
          try {
            const { project, cleanups } = await toActualTypeAndCleanups(vals)
            onOk(project, index, cleanups)
          }
          catch (err) {
            console.error(err)
            message.error(`Something went wrong`)
          }
          setDisabled(false)
          resetForm()
        }}
        enableReinitialize
        initialValues={initialValues}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          isValid,
          errors
        }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group controlId="contact">
                <Form.Label>Title*</Form.Label>
                <Form.Control
                  type="text"
                  name="title"
                  value={values.title}
                  placeholder="Enter title"
                  onChange={handleChange}
                  isInvalid={!!errors.title}
                  isValid={touched.title && !errors.title}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.title}
                </Form.Control.Feedback>


                <Form.Label>Start Date*</Form.Label>
                <Form.Row>
                  <Col>
                    <Form.Control
                      as="select"
                      name="dateRange.monthFrom"
                      value={values.dateRange.monthFrom}
                      // placeholder="Enter title"
                      onChange={handleChange}
                      isInvalid={!!errors.dateRange?.monthFrom}
                      isValid={touched.dateRange?.monthFrom && !errors.dateRange?.monthFrom}
                    >
                      {
                        Object.entries(intMonthToFullMonthMap).map(([monthInt, monthFull]) => <option key={monthInt} value={monthInt}>{monthFull}</option>)
                      }
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.dateRange?.monthFrom}
                    </Form.Control.Feedback>

                  </Col>
                  <Col>
                    <Form.Control
                      type="number"
                      name="dateRange.yearFrom"
                      value={values.dateRange.yearFrom}
                      placeholder="Year"
                      onChange={handleChange}
                      isInvalid={!!errors.dateRange?.yearFrom}
                      isValid={touched.dateRange?.yearFrom && !errors.dateRange?.yearFrom}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.dateRange?.yearFrom}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Row>


                <Form.Label>End Date*</Form.Label>
                <Form.Check
                  name="dateRange.toPresent"
                  checked={values.dateRange.toPresent}
                  label={`Present`}
                  onChange={handleChange}
                />
                {
                  !values.dateRange.toPresent &&
                  <Form.Row>
                    <Col>
                      <Form.Control
                        as="select"
                        name="dateRange.monthTo"
                        value={values.dateRange.monthTo}
                        // placeholder="Enter title"
                        onChange={handleChange}
                        isInvalid={!!errors.dateRange?.monthTo}
                        isValid={touched.dateRange?.monthTo && !errors.dateRange?.monthTo}
                      >
                        {
                          Object.entries(intMonthToFullMonthMap).map(([monthInt, monthFull]) => <option key={monthInt} value={monthInt}>{monthFull}</option>)
                        }
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.dateRange?.monthTo}
                      </Form.Control.Feedback>

                    </Col>
                    <Col>
                      <Form.Control
                        type="number"
                        name="dateRange.yearTo"
                        value={values.dateRange.yearTo}
                        placeholder="Year"
                        onChange={handleChange}
                        isInvalid={!!errors.dateRange?.yearTo}
                        isValid={touched.dateRange?.yearTo && !errors.dateRange?.yearTo}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.dateRange?.yearTo}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Row>
                }
                <Form.Label>Project Media</Form.Label>
                <Form.Check
                  name="embedVideo"
                  checked={values.embedVideo}
                  label={`Embed Video`}
                  onChange={handleChange}
                  style={{ marginBottom: `0.5rem` }}
                />
                {
                  values.embedVideo ?
                    <span>
                      <Form.Control
                        type="text"
                        name="projectVidUrl"
                        value={values.projectVidUrl}
                        placeholder="Enter Video URL"
                        onChange={handleChange}
                        isInvalid={!!errors.projectVidUrl}
                        isValid={touched.projectVidUrl && !errors.projectVidUrl}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.projectVidUrl}
                      </Form.Control.Feedback>
                    </span>
                    :
                    <ImageUpload
                      originalDownloadUrl={values.projectImg}
                      setFile={async (file: File | undefined) => {
                        setNextProjectImg({
                          file,
                          update: true
                        })
                      }}
                      size="md"
                      setSubmitDisabled={async (disabled: boolean) => setDisabled(disabled)}
                    />
                }
                <Form.Label>Project Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  name="description"
                  value={values.description}
                  placeholder="Enter Project Description"
                  onChange={handleChange}
                  isInvalid={!!errors.description}
                  isValid={touched.description && !errors.description}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.description}
                </Form.Control.Feedback>

                <Form.Label>Project URL</Form.Label>
                <Form.Control
                  type="text"
                  name="url"
                  value={values.url}
                  placeholder="Enter Project URL"
                  onChange={handleChange}
                  isInvalid={!!errors.url}
                  isValid={touched.url && !errors.url}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.url}
                </Form.Control.Feedback>
              </Form.Group>
              {
                mode === `edit` &&
                <div style={{ textAlign: `center` }}>
                  <DeleteButton onDelete={async () => {
                    /// we delete the images after it's set
                    if (onDelete) {
                      let cleanups: (() => Promise<void>)[] = []
                      if (item.projectImg) {
                        cleanups.push(await getImageCleanUp(item.projectImg))
                      }
                      await onDelete(cleanups)
                    }

                  }} itemName={itemName} />
                </div>
              }
              <div style={{ textAlign: `center` }}>
                <DiscardChangesButton onDiscard={onCancel} />
                <SaveChangesButton type="submit" disabled={!touched || !isValid || disabled} />
              </div>
            </Form>
          )}
      </Formik>
    </Modal>
  )
}

