import React, { useEffect, useState } from 'react'
import Loading from '../../components/Loading/Loading'
import { useProfileState } from '../../contexts/profileState'
import firebase from 'firebase'
import ErrorComponent from '../../components/Error/Error'

const Logout = () => {
    const [, dispatch] = useProfileState()
    const [error, setError] = useState(false)
    useEffect(() => {
        setTimeout(() => {
            window.localStorage.clear()
            dispatch({})
            firebase.auth().signOut()
                .then(() => {
                    window.location.href = `/`
                })
                .catch((err) => {
                    console.error(err)
                    setError(true)
                })
        }, 1000)
    }, [dispatch])

    if (error) {
        return <ErrorComponent error="Couldn't sign you out."/>
    }

    return <Loading loadingTitle="Logging you out..." />
}

export default Logout
