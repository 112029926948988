import React from "react"

import styles from './FaqPage.module.css'
import { endpoints } from '../../consts';

export type FaqItem = {
  question: string,
  answer: JSX.Element
}


export const FaqPage = () => {

  const faqItems: FaqItem[] = [
    {
      question: "Why can't I sign in with Google?",
      answer: <div>If you use Chrome, and have 3rd party cookies disabled on your browser, you might see the error <i>This browser is not supported or 3rd party cookies and data may be disabled</i>. You can find out how to enable these cookies <a href="https://support.google.com/chrome/answer/95647" target="_blank" rel="noopener noreferrer">here.</a> Otherwise, you can sign in with the Email option.</div>
    },
    {
      question: "Why is my portfolio website not updating?",
      answer: <div>If you have re-generated a new portfolio that is hosted at the same URL as before, it will take up to 10 minutes before you see changes in your browser. This is because your browser caches the old page. You can force refresh with <code>Shift</code> + <code>Command</code> + <code>R</code> on MacOS, and <code>Shift</code> + <code>F5</code> on Windows.</div>
    },
    {
      question: "Can I host the portfolio myself?",
      answer: <div>If you want to host your generated portfolio webpage yourself, because, for example, you want a custom domain name, you can do so by downloading the static portfolio files. After generating your portfolio, in the <a href={endpoints.dashboard}>dashboard</a>, click 'Download portfolio'. You can the upload the static files to a static hosting server of your choice, with whatever domain name you want.</div>
    },
    {
      question: "Can I customize my portfolio further?",
      answer: <div>Yes, but be warned. We allow you to add your own custom HTML to certain fields such as descriptions. For example, you can embed your own hyperlinks to external pages. However, this is a <b>completely unsupported and experimental feature</b>, and we warn you that it is to be used entirely at your own peril. <b>We do not support it</b>, but simply allow it for your convenience.</div>
    },
   
  ]

  return (
    <div style={{ padding: '32px 0' }} className={`${styles.root}`}>
      <div className="card" style={{ maxWidth: '80%', margin: 'auto' }}>
        <div className="card__header" style={{ textAlign: 'center' }}>
          <h3 style={{ fontSize: '2rem', marginBottom: 24 }}>FAQ</h3>
        </div>
        <div className="card__body" style={{ margin: '0 16px' }}>
          {
            faqItems.map((f, i) => {
              return (
                <div key={i} className="card" style={{ backgroundColor: `var(--ifm-color-gray-800)`, padding: 12, marginBottom: 24 }}>
                  <h4 style={{ marginBottom: 8 }}>{f.question}</h4>
                  {f.answer}
                  </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )


}


export default FaqPage