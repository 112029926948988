import React, { useState } from 'react'
import { NextImageType, NextImageDownloadUrlAndCleanup, getNextImageDownloadUrlAndCleanup } from '../common'
import styles from '../common.module.css'
import { SaveChangesButton, DiscardChangesButton } from '../Subcomponents/EditButtons'
import { useProfileState } from '../../../../contexts/profileState'
import { Formik } from 'formik'
import * as yup from 'yup'
import { ImageUpload } from '../Subcomponents/ImageUpload'
import { Form } from 'react-bootstrap'
import { history } from '../../../../utils/history'
import { updateCoreProfile, updateWrapper, removeUndefined } from '../../../../utils/firebase/profile'
import { useLoadingState } from '../../../../contexts/loadingState'
import { message } from 'antd'
import { endpoints } from '../../../../consts'


export const EditCoreProfile = () => {
    const [state,] = useProfileState()
    const [, dispatchLoadingState] = useLoadingState()
    const [disabled, setDisabled] = useState(false)
    const [nextProfilePic, setNextProfilePic] = useState<NextImageType>({
      file: undefined,
      update: false
    })
    const [nextBackgroundPic, setNextBackgroundPic] = useState<NextImageType>({
      file: undefined,
      update: false
    })

    const { profile } = state

    if (!profile) {
      /// should never happen
      throw new Error(`Something went wrong`)
    }

    const uid = state.firebaseCurrentUserId ?? `` /* again, fall back should never happen */

    type FormType = {
      profilePic?: DownloadUrl,
      fullName: string,
      headline?: string,
      location?: string,
      summary?: string,
      backgroundPic?: string
    }

    const initialValues: FormType = {
      profilePic: profile?.profilePic ?? undefined,
      fullName: profile?.fullName ?? `Full Name`,
      headline: profile?.headline,
      location: profile?.location,
      summary: profile?.summary,
      backgroundPic: profile?.backgroundPic ?? undefined
    }

    const toActualTypeAndCleanups = async (vals: FormType): Promise<{ coreProfile: Partial<UserProfile>, cleanups: (() => Promise<void>)[] }> => {
      let cleanups: (() => Promise<void>)[] = []

      const { fullName, headline, location, summary } = vals

      const nextProfilePicDownloadUrlAndCleanup: NextImageDownloadUrlAndCleanup
        = await getNextImageDownloadUrlAndCleanup(`profilePic`, vals.profilePic, nextProfilePic, uid)
      /// set to null to nullify the field
      const profilePic = nextProfilePicDownloadUrlAndCleanup.downloadUrl ?? null
      if (nextProfilePicDownloadUrlAndCleanup.cleanup) {
        cleanups.push(nextProfilePicDownloadUrlAndCleanup.cleanup)
      }


      const nextBackgroundPicDownloadUrlAndCleanup: NextImageDownloadUrlAndCleanup
        = await getNextImageDownloadUrlAndCleanup(`backgroundPic`, vals.backgroundPic, nextBackgroundPic, uid)
      /// set to null to nullify the field
      const backgroundPic = nextBackgroundPicDownloadUrlAndCleanup.downloadUrl ?? null
      if (nextBackgroundPicDownloadUrlAndCleanup.cleanup) {
        cleanups.push(nextBackgroundPicDownloadUrlAndCleanup.cleanup)
      }

      const _coreProfile: Partial<UserProfile> = {
        fullName,
        profilePic,
        headline,
        summary,
        location,
        backgroundPic
      }
      const coreProfile = (await removeUndefined(_coreProfile)) as Partial<UserProfile>

      return {
        coreProfile,
        cleanups
      }
    }

    const schema = yup.object({
      profilePic: yup.mixed(),
      fullName: yup.string()
        .max(256, `Must be 256 characters or fewer`)
        .required(`Required`),
      headline: yup.string()
        .max(512, `Must be 512 characters or fewer`),
      location: yup.string()
        .max(128, `Must be 128 characters or fewer`),
      summary: yup.string()
        .max(4096, `Must be 4096 characters or fewer`),
      backgroundPic: yup.mixed()
    })
    return (
      <div>
        <Formik
          validationSchema={schema}
          onSubmit={async (vals) => {
            setDisabled(true)
            try {
              const { coreProfile, cleanups } = await toActualTypeAndCleanups(vals)
              const updateFunc = async () => await updateCoreProfile(coreProfile, profile)
              await updateWrapper(dispatchLoadingState, updateFunc)
              Promise.all(cleanups.map(async (c) => await c()))
            }
            catch (err) {
              console.error(err)
              message.error(`Something went wrong...`)
            }
            setDisabled(false)
            history.push(`/${endpoints.profile}`)
          }}
          enableReinitialize
          initialValues={initialValues}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            touched,
            isValid,
            errors,
          }) => {
            return (
              <div className={styles.root}>
                <div className={`card ${styles.cardBody}`}>
                  <Form noValidate onSubmit={handleSubmit} style={{ marginLeft: 32, marginRight: 32, textAlign: `left` }}>
                    <Form.Group controlId="education">

                      <Form.Label>Profile Picture</Form.Label>
                      <ImageUpload
                        originalDownloadUrl={values.profilePic}
                        setFile={async (file: File | undefined) => {
                          setNextProfilePic({
                            file,
                            update: true
                          })
                        }}
                        size="sm"
                        setSubmitDisabled={async (disabled: boolean) => setDisabled(disabled)}
                      />
                      <Form.Label>Full Name*</Form.Label>
                      <Form.Control
                        type="text"
                        name="fullName"
                        value={values.fullName}
                        placeholder="Enter full name"
                        onChange={handleChange}
                        isInvalid={!!errors.fullName}
                        isValid={touched.fullName && !errors.fullName}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.fullName}
                      </Form.Control.Feedback>


                      <Form.Label>Headline</Form.Label>
                      <Form.Control
                        type="text"
                        name="headline"
                        value={values.headline}
                        placeholder="Enter headline"
                        onChange={handleChange}
                        isInvalid={!!errors.headline}
                        isValid={touched.headline && !errors.headline}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.headline}
                      </Form.Control.Feedback>

                      <Form.Label>Location</Form.Label>
                      <Form.Control
                        type="text"
                        name="location"
                        value={values.location}
                        placeholder="Enter location"
                        onChange={handleChange}
                        isInvalid={!!errors.location}
                        isValid={touched.location && !errors.location}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.location}
                      </Form.Control.Feedback>


                      <Form.Label>Summary</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        name="summary"
                        value={values.summary}
                        placeholder="Enter summary"
                        onChange={handleChange}
                        isInvalid={!!errors.summary}
                        isValid={touched.summary && !errors.summary}
                      />

                      <Form.Control.Feedback type="invalid">
                        {errors.summary}
                      </Form.Control.Feedback>

                      <Form.Label>Background Picture</Form.Label>
                      <ImageUpload
                        originalDownloadUrl={values.backgroundPic}
                        setFile={async (file: File | undefined) => {
                          setNextBackgroundPic({
                            file,
                            update: true
                          })
                        }}
                        size="lg"
                        setSubmitDisabled={async (disabled: boolean) => setDisabled(disabled)}
                      />


                    </Form.Group>
                    <div style={{ textAlign: `center` }}>
                      <DiscardChangesButton onDiscard={async () => { history.push(`/${endpoints.profile}`) }}/>
                      <SaveChangesButton type="submit" disabled={!touched || !isValid || disabled} />
                    </div>
                  </Form>
                </div>
              </div>
            )
          }}
        </Formik>
      </div>
    )
  }
