import * as React from 'react'
import { Route, Switch } from 'react-router-dom'
import HomePage from '../../HomePage/HomePage'
 import { IS_PRODUCTION, endpoints } from '../../../consts'
import Playground from '../../Playground/Playground'
import Logout from '../../Logout/Logout'
import PortfolioPreview from '../../PortfolioPreview/PortfolioPreview'
import ProfilePage from '../../ProfilePage/ProfilePage'
import PrivacyPolicy from '../../PrivacyPolicy/PrivacyPolicy'
import TermsOfService from '../../TermsOfService/TermsOfService'
import { EditProfilePage } from '../../ProfilePage/EditProfilePage'
import { useProfileState } from '../../../contexts/profileState'
import ErrorComponent from '../../../components/Error/Error'
import { Login } from '../../Login/Login'
import { history } from '../../../utils/history'
import FaqPage from '../../FaqPage/FaqPage';

const AuthRoute = (props:
    { path: string; exact: boolean; component: () => JSX.Element; redirect: string }) => {
    const [state,] = useProfileState()
    const { path, exact, component, redirect } = props
    React.useEffect(() => {
        if (!state.firebaseCurrentUserId) {
            history.push(redirect)
        }
    }, [state, redirect])

    return (
        <Route path={path} exact={exact} component={component} />
    )
}

const NonAuthRoute = (props:
    { path: string; exact: boolean; component: () => JSX.Element; redirect: string }) => {
    const [state,] = useProfileState()
    const { path, exact, component, redirect } = props
    React.useEffect(() => {
        if (state.firebaseCurrentUserId) {
            history.push(redirect)
        }
    }, [state, redirect])

    return (
        <Route path={path} exact={exact} component={component} />
    )
}

const AppContent = () => {
    return (
        <Switch>
            <Route 
                path="/" 
                exact 
                component={HomePage} 
            />
            <Route 
                path={`/${endpoints.faq}`} 
                exact 
                component={FaqPage} 
            />
            <Route
                path={`/${endpoints.privacyPolicy}`}
                exact
                component={PrivacyPolicy}
            />
            <Route
                path={`/${endpoints.termsOfService}`}
                exact
                component={TermsOfService}
            />
            <NonAuthRoute
                path={`/${endpoints.login}`}
                exact
                component={Login}
                redirect={`/${endpoints.profile}`}
            />
            {/* <AuthRoute
                path="/choose-theme"
                exact
                component={ChooseTheme}
                redirect="/login"
            /> */}
            <AuthRoute
                path={`/${endpoints.logout}`}
                exact
                component={Logout}
                redirect="/"
            />
            <AuthRoute
                path={`/${endpoints.dashboard}`}
                exact
                component={PortfolioPreview}
                redirect={`/${endpoints.login}`}
            />
            <AuthRoute
                path={`/${endpoints.profile}`}
                exact
                component={ProfilePage}
                redirect={`/${endpoints.login}`}
            />
            <AuthRoute
                path={`/${endpoints.profile}/edit`}
                exact={false}
                component={EditProfilePage}
                redirect={`/${endpoints.login}`}
            />

            {!IS_PRODUCTION &&

                <Route
                    path="/playground"
                    component={Playground}
                />
            }

            <Route component={() => <ErrorComponent error="We couldn't find the item you're looking for - are you sure you've got the right link?" />}/>
        </Switch>
    )
}

export default AppContent