import * as React from 'react'

import styles from './HomePage.module.css'

// import undraw_react from '../../assets/undraw_react.svg'
// import undraw_domain_names_rdql from '../../assets/undraw_domain_names_rdql.svg'
import undraw_portfolio_update_nqhs from '../../assets/undraw_portfolio_update_nqhs.svg'
import undraw_server_push_vtms from '../../assets/undraw_server_push_vtms.svg'
import undraw_decide from '../../assets/undraw_decide_3iwx.svg'


import herologo from '../../assets/undraw_personal_website_weap.svg'
import { Link } from 'react-router-dom'
import { useProfileState } from '../../contexts/profileState'
import { endpoints } from '../../consts'
import { testimonies } from './Testimony'


const HomePage = () => {
    const [state,] = useProfileState();
    return (
        <div>
            <div className="hero shadow--lw">
                <div className="container">
                    <div className="row">
                        <div className="col col--9">

                            <h1 className={styles.heroProjectTagline}>
                                Make your own {' '}
                                <span className="highlightColor">portfolio webpage</span>{` `}
                                in just a <span className="highlightColor">few minutes</span>{``}
                                , all for <span className="highlightColor">free</span>
                            </h1>
                        </div>
                        <div className="col col--3">
                            <img
                                alt="Portosaurus"
                                className={`${styles.heroLogo}`} //don't have hover, it always triggers on mobile wen scrolling
                                src={herologo}
                            />
                        </div>
                    </div>
                    <div className={styles.generateBtn}>
                        <Link to={state.firebaseCurrentUserId ? `/${endpoints.profile}` : `/${endpoints.login}`}>
                            <button className="button button--secondary button--outline button--lg">
                                Get Started
                                </button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className={styles.section}>

                <div className="container text--center">
                    <div className="row">
                        <div className="col">
                            <img
                                className={`${styles.featureImage} hoverLarge`}
                                alt="Simple To Use"
                                src={undraw_portfolio_update_nqhs}
                            />
                            <h2 className={styles.featureHeading}>
                                <span className="highlightColorLight">Simple</span> To Use
                             </h2>
                            <p className="padding-horiz--md">
                                Save time and focus on your portfolio content. Simply edit your profile details, then preview and generate
                                your portfolio webpage.
                            </p>
                        </div>
                        <div className="col">
                            <img
                                alt="Host for Free"
                                className={`${styles.featureImage} hoverLarge`}
                                src={undraw_server_push_vtms}
                            />
                            <h2 className={styles.featureHeading}>
                                Host for <span className="highlightColorLight">Free</span>
                            </h2>
                            <p className="padding-horiz--md">
                                Portosaurus automatically hosts your portfolio for free! Alternatively, the static files are available for you to download, letting you host anywhere you like.
                             </p>
                        </div>
                        <div className="col">
                            <img
                                alt="Tailored to you"
                                className={`${styles.featureImage} hoverLarge`}
                                src={undraw_decide}
                            />
                            <h2 className={styles.featureHeading}>Tailored to <span className="highlightColorLight">You</span>
                            </h2>
                            <p className="padding-horiz--md">
                                Choose from a selection of prebuilt themes, or make and contribute your very own! Portosaurus makes the perfect portfolio, just for you!
                             </p>
                        </div>
                    </div>


                    <div className="row text-center" style={{ marginTop: '6rem' }}>
                        <div className="col" />
                        <div className="col col--8" >
                            <h1 style={{ marginBottom: 0 }}>Testimonies</h1>
                        </div>
                        <div className="col" />
                    </div>
                    <div style={{ marginTop: '2rem' }} className="row">
                        {testimonies.map(t =>
                            <div className="col">
                                <div className="avatar avatar--vertical margin-bottom--sm">
                                    <a href={t.link} target="_blank" rel="noopener noreferrer" className="hoverLarge">
                                        <img
                                            className={`${styles.profilePic} avatar__photo avatar__photo--xl`}
                                            src={t.img}
                                            style={{ overflow: 'hidden' }}
                                        />
                                    </a>
                                    <div className="avatar__intro padding-top--sm">
                                        <h4 className="avatar__name">{t.fullName}</h4>
                                        <small className="avatar__subtitle">{t.title}</small>
                                    </div>
                                </div>
                                <a href={t.link} target="_blank" rel="noopener noreferrer">
                                    <div className="button button--outline button--success m-3">
                                        {`View portfolio >`}
                                    </div>
                                </a>
                                <p className="text--center text--italic padding-horiz--md" style={{ marginBottom: 0 }}>
                                    "{t.text}"
                                </p>
                                <a href={t.link} target="_blank" rel="noopener noreferrer">

                                </a>
                            </div>
                        )}
                    </div>

                    <div className="row text-center mb-3" style={{ marginTop: '6rem' }}>
                        <div className="col" />
                        <div className="col col--8" >
                            <h1 style={{ marginBottom: 0 }}>More portfolio examples</h1>
                        </div>
                        <div className="col" />
                    </div>
                    <div className="row">
                        <div className="col">

                        </div>
                        <div className="col col--8">
                            <a href="https://portosaurus.github.io/ethansk" target="_blank" rel="noopener noreferrer">
                                <div className="button button--outline button--success m-3">
                                    {`Ethan's portfolio >`}
                                </div>
                            </a>

                            <a href="https://portosaurus.github.io/lhl2617/" target="_blank" rel="noopener noreferrer">
                                <div className="button button--outline button--success m-3">
                                    {`LH's portfolio >`}
                                </div>
                            </a>
                            <a href="https://portosaurus.github.io/sebfenton/" target="_blank" rel="noopener noreferrer">
                                <div className="button button--outline button--success m-3">
                                    {`Seb's portfolio >`}
                                </div>
                            </a>
                        </div>
                        <div className="col">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomePage
