import React, { useState, useEffect } from 'react'
// import Loading from '../../components/Loading/Loading'
// import ErrorComponent from '../../components/Error/Error'
import { useProfileState } from '../../contexts/profileState';


type Props = {
    // refreshToggle: boolean; // toggled to refresh
    // setRefreshButtonEnabled: (enabled: boolean) => void;
    portfolioThemeName: PortfolioThemeName
}


const PortfolioPreviewFrame = (props: Props) => {
    const { portfolioThemeName } = props
    const [isShowing, setIsShowing] = useState(true) //for force render
    const [profileState,] = useProfileState()

    useEffect(() => {
        setIsShowing(false)
    }, [profileState.userPreferences?.portfolioSectionOrder])

    useEffect(() => {
        if (!isShowing) setIsShowing(true) //force rerender
    }, [isShowing])

    return (
        <div style={{ margin: `auto` }}>
            {isShowing && <iframe title="Portfolio Preview" src={`/portfolios/${portfolioThemeName}/`} style={{ borderColor: 'whitesmoke', borderRadius: 10, display: `block`, margin: `auto`, overflow: `auto`, width: `90vw`, minHeight: `90vh` }} />}
        </div>
    )
}

export default PortfolioPreviewFrame