import yusufProfPic from '../../assets/testimony/yusuf.jpg'
import eirikProfPic from '../../assets/testimony/eirik.jpg'
import hardikProfPic from '../../assets/testimony/hardik.jpg'

type Testimony = {
    fullName: string
    title: string
    img: string
    text: string
    link: string
}

export const testimonies: Testimony[] = [
    {
        fullName: "Yusuf Ismail",
        title: "Software Engineer, Starleaf",
        img: yusufProfPic,
        text: `Super happy with my experience with Portosaurus.
        It produces a very clean and professional looking portfolio with very minimal fuss,
        and provides free hosting or the ability to host yourself, not something that can be said of most solutions.`,
        link: "https://qfsw.co.uk/portfolio/",
    },
    {
        fullName: "Eirik Albrektsen",
        title: "FPGA Engineer, Jump Trading",
        img: eirikProfPic,
        text: `Portosaurus provided a clean, customizable, and easy to use service to showcase my projects.
        Overall a fantastic tool for people who want an easy way to add a beautiful online presence.`,
        link: "https://portosaurus.github.io/eirikalb/",
    },
    {
        fullName: "Hardik Aggarwal",
        title: "EIE Student, Imperial College",
        img: hardikProfPic,
        text: `I absolutely love the site - it is so easy to navigate through. No prerequisites for building your own portfolio. It even provides free hosting! Highly recommended to everyone looking to get a sleek, designer-free website without any hassle.`,
        link: "https://portosaurus.github.io/HardikAgggarwal/",
    },
]
