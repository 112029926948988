import React from 'react'
import { ItemModProp } from '../common'
import { Form } from 'react-bootstrap'
import { Formik } from 'formik'
import * as yup from 'yup'
import { removeUndefined } from '../../../../utils/firebase/profile'
import { pluralItemName, itemName } from './Skills'

export const AddNewSkillTextBox = (props: { itemModProp: ItemModProp<Skill>; allSkills: Skill[]; }) => {
    const { itemModProp, allSkills } = props
    const { item, onOk, index } = itemModProp

    const schema = yup.object({
        name: yup.string()
            .required(`Required`)
            .min(1, `Required`)
            .max(32, `Must be 32 characters or fewer`)
            .test(`No duplicate`, `This skill already exists`, (val: string) => {
                /// input is pre-trimmed already, so we need to trim and check the current values
                return !allSkills.map(x => x.name).includes(val.trim())
            })
            .test(`Limit to 5000`, `You can only add up to 5000 ${pluralItemName}`, (_) => {
                return allSkills.length < 5000
            }),
        url: yup.string()
            .url(`Please enter a valid URL, e.g. https://www.example.com`)
            .max(128, `Must be 128 characters or fewer`)
    })


    return (
        <div className="card" style={{ backgroundColor: `var(--ifm-color-gray-800)`, padding: `16px 40px` }}>
            <Formik
                enableReinitialize
                validationSchema={schema}
                onSubmit={async (vals, { resetForm }) => {
                    const _skillToInsert: Skill = {
                        ...vals,
                        url: vals.url.length ? vals.url : undefined
                    }
                    const skillToInsert = (await removeUndefined(_skillToInsert)) as Skill
                    onOk(skillToInsert, index)
                    resetForm()
                }}
                initialValues={{
                    name: item.name,
                    url: ``
                }}
            >
                {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    isValid,
                    errors,
                }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group controlId="name">
                                <Form.Label><div style={{ fontSize: `1rem` }}>Add new {itemName}</div></Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    value={values.name}
                                    placeholder="Enter skill"
                                    onChange={handleChange}
                                    isInvalid={!!errors.name}
                                    isValid={touched.name && !errors.name}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.name}
                                </Form.Control.Feedback>
                                <Form.Control
                                    style={{ marginTop: 8 }}
                                    type="text"
                                    name="url"
                                    value={values.url}
                                    placeholder="Enter URL (optional)"
                                    onChange={handleChange}
                                    isInvalid={!!errors.url}
                                    isValid={touched.url && !errors.url}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.url}
                                </Form.Control.Feedback>
                                <button style={{ marginTop: 8 }} className="button button--primary" type="submit" disabled={!touched || !isValid}>Add</button>
                            </Form.Group>
                        </Form>
                    )}
            </Formik>
        </div>
    )
}
