import * as yup from 'yup'

export const monthYearLessThan = (a: { sMonth: number | string, year: number }, b: { sMonth: number | string, year: number }) => {
    const yearFrom = a.year
    const yearTo = b.year

    /// because javascript doesn't know it's a string/number at the time of validation, we need
    /// to force it to Number type
    const monthFrom = Number(a.sMonth)
    const monthTo = Number(b.sMonth)


    return (yearFrom * 12 + monthFrom) < (yearTo * 12 + monthTo)
}

/**
 * THIS IS BROKEN, THE ORDER IS SCREWED,
 * We never use this anyway - just use manual drag and drop
 */
// /// sort order follows LinkedIn's, descending from present 
// const monthYearComparator = (a: { monthFrom: number; yearFrom: number }, b: { monthFrom: number; yearFrom: number }): number => {
//     const aVal = a.yearFrom * 12 + a.monthFrom
//     const bVal = b.yearFrom * 12 + b.monthFrom

//     return bVal - aVal
// }

// export const dateRangeComparator = (a: DateRange, b: DateRange): number => {
//     if (!a.toPresent && b.toPresent) {
//         return 1
//     }
//     if (a.toPresent && b.toPresent) {
//         return monthYearComparator({ monthFrom: a.monthFrom, yearFrom: a.yearFrom }, { monthFrom: b.monthFrom, yearFrom: b.yearFrom })
//     }
//     if (a.toPresent && !b.toPresent)  {
//         return -1
//     }
//     return monthYearComparator({ monthFrom: a.monthTo ?? 0, yearFrom: a.yearTo ?? currentYear }, { monthFrom: b.monthTo ?? 0, yearFrom: b.yearTo ?? currentYear })
// }

// type DateRangeItem = Project | Education | JobPosition
// export const dateRangeItemComparator = (a: DateRangeItem, b: DateRangeItem) => {
//     return dateRangeComparator(a.dateRange, b.dateRange)
// }

export const intMonthToFullMonthMap: Record<number, string> = {
    0: `January`,
    1: `February`,
    2: `March`,
    3: `April`,
    4: `May`,
    5: `June`,
    6: `July`,
    7: `August`,
    8: `September`,
    9: `October`,
    10: `November`,
    11: `December`,
}


export const dateRangeDisplay = (x: DateRange): string => {
    let res: string
    const { monthFrom, monthTo, yearFrom, yearTo, toPresent } = x

    if (!toPresent && monthTo !== undefined && yearTo !== undefined) {
        /// if monthTo and yearTo corrupt, assume present
        const from = monthYearDateDisplay(monthFrom, yearFrom)
        if (monthFrom === monthTo && yearFrom === yearTo) {
            res = from
        }
        else {
            const to = monthYearDateDisplay(monthTo, yearTo)
            res = `${from} - ${to}`
        }
    }
    else {
        const from = monthYearDateDisplay(monthFrom, yearFrom)
        const to = `Present`
        res = `${from} - ${to}`
    }
    return res
}

export const monthYearDateDisplay = (month: number, year: number): string => {
    return `${intMonthToFullMonthMap[month]} ${year}`;
}

export const yearRangeDisplay = (x: DateRange): string => {

    let res: string
    const { yearFrom, yearTo, toPresent } = x

    if (!toPresent && yearTo) {
        /// if yearTo corrupt, assume present
        const from = `${yearFrom}`
        if (yearFrom === yearTo) {
            res = from
        }
        else {
            const to = `${yearTo}`
            res = `${from} - ${to}`
        }
    }
    else {
        const from = `${yearFrom}`
        const to = `Present`
        res = `${from} - ${to}`
    }
    return res
}

export const yupYearValidator = yup.number()
    .test(`valid year`, `Enter year in full format, e.g. 2020`, function (val) {
        const valstr = val.toString()
        const matched = valstr.match(/^[1-9][0-9]{3}$/) ? true : false
        return matched
    })
