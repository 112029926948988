import React from 'react'

import styles from './ProfilePage.module.css'
import { CoreProfile } from './Components/CoreProfile/CoreProfile'
import { Educations } from './Components/Educations/Educations'
import { JobPositions } from './Components/JobPositions/JobPositions'
import { Contacts } from './Components/Contacts/Contacts'
import { Skills } from './Components/Skills/Skills'
import { Projects } from './Components/Projects/Projects'
import { Link, useHistory } from 'react-router-dom'
import { endpoints } from '../../consts'
import { Awards } from './Components/Awards/Awards'

const ProfilePage = () => {
    const history = useHistory()
    return (
        <div className={styles.root}>
            <h1>Profile</h1>
            <h3 style={{ fontWeight: 500 }}>Update your profile, and <Link to={`/${endpoints.dashboard}`}>generate your portfolio</Link></h3>
            <CoreProfile />
            <JobPositions />
            <Projects />
            <Educations />
            <Awards />
            <Skills />
            <Contacts />
            <button 
            onClick={() => history.push(`/${endpoints.dashboard}`)}
            style={{ marginTop: 24 }}
              className={`button button--lg button--success`} >Dashboard</button>
        </div>
    )
}

export default ProfilePage