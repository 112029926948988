import React, { useState } from "react";
import { ItemModProp } from "../common";
import {
  DiscardChangesButton,
  SaveChangesButton,
  DeleteButton,
} from "../Subcomponents/EditButtons";
import {
  intMonthToFullMonthMap,
  yupYearValidator,
} from "../../../../utils/datetime";
import { removeUndefined } from "../../../../utils/firebase/profile";
import { Modal, message } from "antd";
import { Formik } from "formik";
import * as yup from "yup";
import { Form, Col } from "react-bootstrap";
import { itemName } from "./Awards";

export const AddEditDeleteModal = (props: {
  itemModProp: ItemModProp<Award>;
  show: boolean;
}) => {
  const [disabled, setDisabled] = useState(false);
  const { show, itemModProp } = props;
  const { item, onOk, onCancel, mode, index, onDelete } = itemModProp;
  const addOrEdit = mode === `add` ? `Add` : `Edit`;

  type FormType = {
    name: string;
    issuer: string;
    date: {
      month: number;
      year: number;
    };
    description?: string;
  };

  const initialValues: FormType = {
    name: item.name,
    issuer: item.issuer,
    date: item.date,
    description: item.description,
  };

  const toActualType = async (vals: FormType): Promise<Award> => {
    const { name, issuer, date, description } = vals;

    const _award: Award = {
      name,
      issuer,
      date,
      description: description ?? ``,
    };

    const award = (await removeUndefined(_award)) as Award;
    return award;
  };

  const schema = yup.object({
    name: yup
      .string()
      .max(128, `Must be 128 characters or fewer`)
      .required(`Required`),
    issuer: yup
      .string()
      .max(128, `Must be 128 characters or fewer`)
      .required(`Required`),
    date: yup.object({
      month: yup
        .string()
        .oneOf(Object.keys(intMonthToFullMonthMap))
        .required(`Required`),
      year: yupYearValidator.required("Required"),
    }),
    description: yup.string().max(4096, `Must be 4096 characters or fewer`),
  });

  return (
    <Modal
      title={`${addOrEdit} ${itemName}`}
      visible={show}
      centered
      footer={null}
      onCancel={onCancel}
      maskClosable={false}
      closable={false}
      destroyOnClose
      keyboard={false}
      zIndex={1021}
    >
      <Formik
        validationSchema={schema}
        onSubmit={async (vals, { resetForm }) => {
          setDisabled(true);
          try {
            const award: Award = await toActualType(vals);
            onOk(award, index);
          } catch (err) {
            console.error(err);
            message.error(`Something went wrong`);
          }
          setDisabled(false);
          resetForm();
        }}
        enableReinitialize
        initialValues={initialValues}
      >
        {({ handleSubmit, handleChange, values, touched, isValid, errors }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group controlId="jobPosition">
              <Form.Label>Name*</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={values.name}
                placeholder="Enter name"
                onChange={handleChange}
                isInvalid={!!errors.name}
                isValid={touched.name && !errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>

              <Form.Label>Issuer*</Form.Label>
              <Form.Control
                type="text"
                name="issuer"
                value={values.issuer}
                placeholder="Enter issuer"
                onChange={handleChange}
                isInvalid={!!errors.issuer}
                isValid={touched.issuer && !errors.issuer}
              />
              <Form.Control.Feedback type="invalid">
                {errors.issuer}
              </Form.Control.Feedback>

              <Form.Label>Date*</Form.Label>
              <Form.Row>
                <Col>
                  <Form.Control
                    as="select"
                    name="date.month"
                    value={values.date.month}
                    // placeholder="Enter title"
                    onChange={handleChange}
                    isInvalid={!!errors.date?.month}
                    isValid={touched.date?.month && !errors.date?.month}
                  >
                    {Object.entries(intMonthToFullMonthMap).map(
                      ([monthInt, monthFull]) => (
                        <option key={monthInt} value={monthInt}>
                          {monthFull}
                        </option>
                      )
                    )}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.date?.month}
                  </Form.Control.Feedback>
                </Col>
                <Col>
                  <Form.Control
                    type="number"
                    name="date.year"
                    value={values.date.year}
                    placeholder="Year"
                    onChange={handleChange}
                    isInvalid={!!errors.date?.year}
                    isValid={touched.date?.year && !errors.date?.year}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.date?.year}
                  </Form.Control.Feedback>
                </Col>
              </Form.Row>

              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                name="description"
                value={values.description}
                placeholder="Enter description"
                onChange={handleChange}
                isInvalid={!!errors.description}
                isValid={touched.description && !errors.description}
              />
              <Form.Control.Feedback type="invalid">
                {errors.description}
              </Form.Control.Feedback>
            </Form.Group>
            {mode === `edit` && (
              <div style={{ textAlign: `center` }}>
                <DeleteButton onDelete={onDelete} itemName={itemName} />
              </div>
            )}
            <div style={{ textAlign: `center` }}>
              <DiscardChangesButton onDiscard={onCancel} />
              <SaveChangesButton
                type="submit"
                disabled={!touched || !isValid || disabled}
              />
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
