import React, { useEffect, useState } from "react";

import Loading from "../../components/Loading/Loading";
import { Contacts } from "./Components/Contacts/Contacts";
import { CoreProfile } from "./Components/CoreProfile/CoreProfile";
import ErrorComponent from "../../components/Error/Error";
import { Educations } from "./Components/Educations/Educations";
import { JobPositions } from "./Components/JobPositions/JobPositions";
import { Projects } from "./Components/Projects/Projects";
import { Skills } from "./Components/Skills/Skills";
import { Awards } from "./Components/Awards/Awards";

type EditPageKey =
  | `contacts`
  | `info`
  | `education`
  | `experience`
  | `projects`
  | `skills`
  | `awards`;

export const EditProfilePage = () => {
  const [pageKey, setPageKey] = useState<EditPageKey | undefined>(undefined);

  useEffect(() => {
    const href = window.location.href;
    const sepKeys = href.split(`/`);
    const pageKey = sepKeys[sepKeys.length - 1] as EditPageKey;
    setTimeout(() => setPageKey(pageKey), 1000);
    // Too many problems--rather not risk it showing false positives!
    // window.onbeforeunload = () => { return `Are you sure you want to leave? Changes, if any, will be lost.` }
    // return () => {
    //     window.onbeforeunload = null
    // }
  });

  const getEditComponent = () => {
    switch (pageKey) {
      case undefined:
        return <Loading />;
      case `contacts`:
        return <Contacts mode="edit" />;
      case `info`:
        return <CoreProfile mode="edit" />;
      case `education`:
        return <Educations mode="edit" />;
      case `experience`:
        return <JobPositions mode="edit" />;
      case `projects`:
        return <Projects mode="edit" />;
      case `skills`:
        return <Skills mode="edit" />;
      case "awards":
        return <Awards mode="edit" />;
      default:
        return <ErrorComponent />;
    }
  };

  return (
    <span>
      {/* Technically having this prompt here is bad practice (there should be a state/context determining
            whether routing should be blocked) -- it shouldn't be blocked when we are saving changes

            The way we get around this is to have the updateWrapper
            set a timeout on the loading state, making sure this component is unmounted => Prompt disabled.

            Update 2021-03-16: Too many problems--rather not risk it showing false positives!
            */}
      {/* <Prompt when={true} message={`Are you sure you want to leave? Changes, if any, will be lost.`} /> */}
      {getEditComponent()}
    </span>
  );
};
