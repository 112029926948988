import React, { useState, useEffect } from 'react'
import styles from './PortfolioPreview.module.css'
import PortfolioPreviewFrame from './PortfolioPreviewFrame'
import Loading from '../../components/Loading/Loading';
import get from 'axios';
import { portfolioHostingIdText, endpoints, serverUrl, portfolioBuildTimeoutSecs } from '../../consts';
import ErrorComponent from '../../components/Error/Error';
import axios from 'axios';
import firebase from 'firebase';
import { useProfileState } from '../../contexts/profileState';
import { Link } from 'react-router-dom';
import PortfolioSettingsForm, { PortfolioSettings } from './Components/PortfolioSettings';
import { Popconfirm } from 'antd';
import {updateUser} from "../../utils/firebase/firebase"


const PortfolioPreview = () => {
    const [isBuldingFullPortfolio, setIsBuldingFullPortfolio] = useState(false)
    const [profileState,] = useProfileState()
    const [isBuildButtonEnabled, setIsBuildButtonEnabled] = useState(false) //false by default, only set to true once we have retrieved the current portfolio build job
    const [error, setError] = useState<string | null>()
    const [isRepoNameAvailable, setIsRepoNameAvailable] = useState<boolean>(true)
    const [isDownloadingPortfolio, setIsDownloadingPortfolio] = useState(false)
    const [isDeletingPortfolio, setIsDeletingPortfolio] = useState(false)
    const [forceShowLoading, setForceShowLoading] = useState(false);
 

     useEffect(() => {
        if (profileState.isFirebaseAuthReady) {
            if (!isBuldingFullPortfolio) {
                if (isPortfolioJobRunning()) {
                    setIsBuldingFullPortfolio(true)
                    console.log("Found currently running job for full profile build with jobId " + profileState.userDocFirestore?.currentFullPortfolioBuildJobInfo?.jobId)
                } else {
                    //  setIsBuildButtonEnabled(true)
                }
            }
           
        }
    }, [profileState])

    useEffect(() => {
        const curJobInfo = profileState.userDocFirestore?.currentFullPortfolioBuildJobInfo
        const curGHPagesInfo = profileState.userDocFirestore?.currentGithubPagesInfo

        if (curJobInfo?.state === "failed") {
            setError(curJobInfo.failedReason)
        }
        if (curGHPagesInfo?.status === "errored") {
            setError(`There was an error hosting your website :/`)
        }
        setIsBuldingFullPortfolio(isPortfolioJobRunning())

        //don't check !isGithubPagesBuilding() because that can take a really long time sometimes, and we shouldn't block on it. we also haven't implemented funtionality to allow the user to retry after timeout, like we did for job building
        setIsBuildButtonEnabled(!isPortfolioJobRunning())


    }, [
        profileState.userDocFirestore?.currentFullPortfolioBuildJobInfo, profileState.userDocFirestore?.currentGithubPagesInfo
    ])


    async function handleBuildPortfolioClick(settings: PortfolioSettings) {
        await buildFullPortfolio(settings)
    }



    async function handleFinishedTypingRepoName(repoName: string) {
        // console.log("finihsed typing repo name: ", repoName)
        const isRepoAvail = await checkIfRepoNameIsAvailable(repoName)
        setIsRepoNameAvailable(isRepoAvail)
    }

    function hasReachedLoadingTimeout(): boolean {
        return (Date.now() / 1000) - portfolioBuildTimeoutSecs > (profileState.userDocFirestore?.timeLastTriedPortfolioBuild?.seconds ?? 0)
    }



    const buildFullPortfolio = async (settings: PortfolioSettings) => {
        if (!settings.repoName) return console.error(`You have to set a ${portfolioHostingIdText}!`)
        if (isBuldingFullPortfolio && !hasReachedLoadingTimeout()) return console.log("Already building") //allow them to rebuild if has reach loading timeout. this is correct.
        if (hasReachedLoadingTimeout()){
            setIsBuildButtonEnabled(true)
        }
        // if (isGithubPagesBuilding()) return console.log("Wait until hosting completes before trying to build again.") //don't do this or user could get softlocked if github fux up
        setIsBuldingFullPortfolio(true) //must do it here so the user can't quickly press build twice

        if (!(await (checkIfRepoNameIsAvailable(settings.repoName)))) {
            console.error("repo name not available when tryna build", settings.repoName)
            setIsRepoNameAvailable(false)
            setIsBuldingFullPortfolio(false)
            return
        }
        setError(null)
        setIsBuildButtonEnabled(false)
        console.log("buildFullPortfolio", settings.repoName)

        try {
            if (profileState.firebaseCurrentUserId ) {
                let currentFullPortfolioBuildJobInfo: PortfolioBuildJobInfo | undefined = profileState.userDocFirestore?.currentFullPortfolioBuildJobInfo
                if (!currentFullPortfolioBuildJobInfo){
                   currentFullPortfolioBuildJobInfo = {
                       repoName: settings.repoName,      
                       jobId: Date.now().toString() + profileState.firebaseCurrentUserId,
                       state: "waiting",
                       progress: 0,             
                   } //will get set too in functions but need to set here too so that right after deleteing it can still save the reponame
                }
                currentFullPortfolioBuildJobInfo = {     
                    ...currentFullPortfolioBuildJobInfo,
                    state: "waiting",
                }
                await updateUser(profileState.firebaseCurrentUserId,
                  {
                    currentFullPortfolioBuildJobInfo,
                    timeLastTriedPortfolioBuild: firebase.firestore.Timestamp.fromDate(new Date())
                  })
              }

            const portfolioTheme: PortfolioThemeName = profileState.userDocFirestore?.portfolioThemeName ?? "infima_dark"
            const params = {
                firebase_id_token: await firebase.auth().currentUser?.getIdToken(),
                github_repo_name: settings.repoName,
                portfolio_theme: portfolioTheme,
            }
            const data = {
                user_profile: profileState.profile,
                user_preferences: profileState.userPreferences
            }
            const res = await axios({
                method: "post",
                url: `${serverUrl}/portfolio/generate_full_build`,
                params,
                data
            })

            console.log(res.data)

        } catch (error) {
            console.log("error in full portfolio generation request: ")
            console.dir(error.response.data)
            setError(error.response.data)
        }

    }

    async function downloadPortfolioZip() {
        setIsDownloadingPortfolio(true)
        try {
            const repoName = profileState.userDocFirestore?.repoNameCurrentlyInUse
            const res = await get(`${serverUrl}/github/get_download_url/${repoName}`, { params: { firebase_id_token: await firebase.auth().currentUser?.getIdToken() } })
            window.open(res.data)
        } catch (error) {
            console.error(`Error downloading portfolio zip: ${error}`)
            setError(error.message)
        }

        setIsDownloadingPortfolio(false)
    }

    const deletePortfolio = async () => {
        setIsDeletingPortfolio(true)
        try {
            const repoNameToDelete = profileState.userDocFirestore?.repoNameCurrentlyInUse //we should get the actual repo being used instead of what's in the text box so it's 100% correct
            if (!repoNameToDelete) throw new Error(`Was trying to delete repo but there is no repo name to delete!`)
            await axios.delete(`${serverUrl}/github/delete_repo/${repoNameToDelete}`, {
                params: {
                    firebase_id_token: await firebase.auth().currentUser?.getIdToken()
                }
            })
        } catch (error) {
            console.error(`Error deleting portfolio: ${error}`)
            setError(error.message)
        }
        setIsDeletingPortfolio(false)
    }

    async function checkIfRepoNameIsAvailable(repoName: string): Promise<boolean> {
        console.log(`seeing if ${repoName} is available`)
        if (profileState.userDocFirestore?.repoNameCurrentlyInUse === repoName) return true
        const res = await get(`${serverUrl}/github/is_repo_name_available/${repoName}`, { params: { firebase_id_token: await firebase.auth().currentUser?.getIdToken() } })
        console.log(res.data)
        return res.data
    }

    function isGithubPagesBuilding(): boolean {
        const status = profileState.userDocFirestore?.currentGithubPagesInfo?.status
        return !!status && status !== "errored" && status !== "built"
    }


    function isPortfolioJobRunning(): boolean {
        const state = profileState.userDocFirestore?.currentFullPortfolioBuildJobInfo?.state
        return state === "waiting" || state === "delayed" || state === "active"
    }

    function hostedPortfolioUrl() {
        return profileState.userDocFirestore?.currentGithubPagesInfo?.hostedUrl
    }

    function settingsFormInitialRepoName(userDocFirestore: UserDocFirestore) {
        if (isPortfolioJobRunning()) {
            return userDocFirestore.currentFullPortfolioBuildJobInfo?.repoName
                ?? userDocFirestore.repoNameCurrentlyInUse
        } else {
            return userDocFirestore.repoNameCurrentlyInUse
        }
    }

    function getLoadingTitleAndText() {
        let title: string | undefined
        let text: string | undefined
        if (isPortfolioJobRunning() || isBuldingFullPortfolio) {
            title = "Hang tight!"
            text = "We're building your portfolio website. This may take a few minutes."
        }
        else if (isGithubPagesBuilding()) {
            title = "Almost there!"
            text = "We're hosting your portfolio website."
        }
        else if (isDeletingPortfolio) {
            title = "Just a moment"
            text = "We're deleting your portfolio completely."
        }
        return { title, text }
    }

    return (
        <div className={styles.root}>

            {(isPortfolioJobRunning() || isGithubPagesBuilding()) && !error && !hasReachedLoadingTimeout() &&
                <div className="justify-content-center wide-for-mobile" style={{ marginTop: 0 }}>
                    <div className={`alert alert--warning ${styles.alert}`} role="alert" style={{ color: `black` }}>
                        Any changes made now will <strong>not</strong> be applied to the currently generating build.
                    </div>
                </div>
            }
            {!isPortfolioJobRunning() && hostedPortfolioUrl() && profileState.userDocFirestore?.currentGithubPagesInfo?.status === "built" &&
                <>
                    <h1 style={{ marginBottom: 4 }} >Your portfolio is ready</h1>

                    <button
                        onClick={() => window.open(hostedPortfolioUrl()!)} style={{ marginBottom: 0 }}
                        className={`button button--success`} type="submit" >Go to live portfolio
                    </button>

                    <button
                        onClick={() => !isDownloadingPortfolio && downloadPortfolioZip()} style={{ marginBottom: 0 }}
                        className={`button button--outline button--success`} type="submit" >Download portfolio
                    </button>

                    <Popconfirm onConfirm={() => deletePortfolio()} title="Are you sure you want to completely delete your hosted portfolio? All links to your current portfolio will no longer work. This cannot be undone." cancelText="No" okText="Yes">
                        <button
                            style={{ marginBottom: 0 }}
                            className={`button button--outline button--danger`} type="submit" >Delete portfolio
                    </button>
                    </Popconfirm>
                </>
            }



            {error && <ErrorComponent error={`Error on PortfolioPreview page: ${error}`}></ErrorComponent>}


            {(isBuldingFullPortfolio || isPortfolioJobRunning() || isGithubPagesBuilding() || isDeletingPortfolio) && !error && !hasReachedLoadingTimeout() && <Loading loadingTitle={getLoadingTitleAndText().title} loadingText={getLoadingTitleAndText().text} />}

            {/* only show PortfolioSettings when the initial values are present so the formik initialValues is correct and we don't have to enableReinitialization. */}
            {profileState.userDocFirestore &&
                <div style={{ marginTop: "50px" }}>
                    <PortfolioSettingsForm
                        isBuildButtonEnabled={(isBuildButtonEnabled && !isDeletingPortfolio) || hasReachedLoadingTimeout()}
                        isRepoNameAvailable={isRepoNameAvailable}
                        initialRepoName={settingsFormInitialRepoName(profileState.userDocFirestore)}
                        handleBuildPortfolioClick={handleBuildPortfolioClick}
                        handleUserStoppedTypingRepoName={handleFinishedTypingRepoName} />
                </div>
            }


            <h2 style={{ marginTop: "35px" }}>Portfolio Preview</h2>
            <h5>Update your <Link to={`/${endpoints.profile}`}>profile</Link>, and  see the changes!</h5>

            <h5 style={{ marginBottom: 12 }}>When you're happy with the results, build it and we'll take care of the rest.</h5>
            {
                <PortfolioPreviewFrame portfolioThemeName={profileState.userDocFirestore?.portfolioThemeName ?? "infima_dark"} />
            }
        </div>
    )
}

export default PortfolioPreview