import firebase from "firebase/app"
import "firebase/firestore"
import "firebase/storage"
import "firebase/analytics"
import "firebase/auth"
import { firebaseConsts } from '../../consts'

export const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyBS1Z9oZCJhqZuKiE75U1PuVOX-sal4TvE",
  authDomain: "portosaurus-cf425.firebaseapp.com",
  databaseURL: "https://portosaurus-cf425.firebaseio.com",
  projectId: "portosaurus-cf425",
  storageBucket: "portosaurus-cf425.appspot.com",
  messagingSenderId: "631170870143",
  appId: "1:631170870143:web:2d64a8e57fbbf5a378c84a",
  measurementId: "G-MYTCWJXGN9"
})

export const firestore = firebaseApp.firestore()
export const storage = firebaseApp.storage()
export const auth = firebaseApp.auth()

export async function updateUser(
  docId: FirestoreDocId,
  data: Partial<UserDocFirestore>
) {
  if (!firebase.auth().currentUser) throw new Error("Tried to updateUser but user doesn't seem to be logged in")
  await firestore
    .collection(firebaseConsts.usersCollection)
    .doc(docId)
    .set({ ...data }, { merge: true })
}


export const updateCurrentUser =
  async (data: Partial<UserDocFirestore>) => {
    const uid = firebase.auth().currentUser?.uid
    if (!uid) {
      throw new Error(`No active user!`)
    }
    await firestore
      .collection(firebaseConsts.usersCollection)
      .doc(uid)
      .set({ ...data }, { merge: true })
  }

export const updateCurrentUserPromise = (data: Partial<UserDocFirestore>) => async () => {
  return updateCurrentUser(data)
}

export async function deleteFieldFromUserDoc(docId: FirestoreDocId, field: keyof UserDocFirestore) {
  // console.log(`deleting field ${field} from ${docId}`)
  await firestore
    .collection(firebaseConsts.usersCollection)
    .doc(docId)
    .update({
      [field]: firebase.firestore.FieldValue.delete()
    })
}


export async function getUser(docId: string): Promise<UserDocFirestore | null> {
  const doc = await firestore.collection(firebaseConsts.usersCollection).doc(docId).get()
  const data = doc.data()

  if (!doc || !doc.exists) {
    console.log(
      "Couldn't find user in firestore with docId " + docId
    )
    return null
  }
  if (!data) {
    console.log(
      `User doc with id ${docId} has no data`
    )
    return null
  }
  return data as UserDocFirestore

}
