import React, { useState } from 'react'
import { ProfileComponentProps, defaultProfileComponentProps, ItemModProp, addItemToArray, deleteItemFromArray } from '../common'
import styles from '../common.module.css'
import { joinStringsWithDot, replaceNewlinesWithBr } from '../../../../utils/utils';
import defaultInsititution from '../../../../assets/defaultInstitution.png'
import { MainEditButton, AddNewButton, IndivEditButtons, DoneButton } from '../Subcomponents/EditButtons'
import { useProfileState } from '../../../../contexts/profileState'
import { useLoadingState } from '../../../../contexts/loadingState'
import { message } from 'antd'
import { updateEducations, updateWrapper } from '../../../../utils/firebase/profile'
import { currentYear, endpoints } from '../../../../consts'
import { history } from '../../../../utils/history'
import { AddEditDeleteModal } from './Form'
import { yearRangeDisplay } from '../../../../utils/datetime';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import DragHandle from '../../../../components/DragHandle/DragHandle';
import useIsMobileDevice from '../../../../utils/useIsMobileDevice';
import InnerHTML from 'dangerously-set-html-content'

export const itemName = `education`
export const pluralItemName = `education`
export const id = `education`

// const testedus: Education[] = [
//   {
//     degreeName: `MEng`,
//     fieldOfStudy: `Electronic & Information Engineering`,
//     activities: [`Lacrosse`, `Tennis Club`, `Jeebus`],
//     grade: `2:1`,
//     url: `https://www.imperial.ac.uk/`,
//     schoolName: `Imperial College London`,
//     schoolLogo: `https://www.jobs.ac.uk/enhanced/employer/imperial-college-london/images/default-logo.png`,
//     description: [`1st Year: Introduction to Computing, Algorithms and Data Structures, Introduction to Computer Architecture , Analysis of Circuits, Digital Electronics 1, Introduction to Signals and Communications, and Systems, Mathematics 1, User-Centred Information Systems, Computing Lab, Electronics Lab, FPGA project.`,
//       `Grade: 2:1`,
//       ``,
//       `2nd Year: Algorithms and Complexity, Object-Oriented Software Engineering, Computer Architecture, Language Processors, Digital Electronics 2, Communication Systems, Signals and Linear Systems, Mathematics 2, Feedback Systems, Computing Lab, Electronics Lab`,
//       `Grade: 2:1`,
//       ``,
//       `3rd Year (upcoming): High level programming, Embedded systems, Robotics, Networks and Web Security, Computer Vision, Machine Learning, Advanced Computer Architecture, Entrepreneurship`
//     ],
//     yearFrom: 2017,
//     yearTo: 2021
//   }, {
//     degreeName: `MEng`,
//     fieldOfStudy: `Electronic & Information Engineering`,
//     schoolName: `King's College London`,
//     yearFrom: 2017,
//     yearTo: 2021
//   },
// ]

export const Educations = (props: ProfileComponentProps) => {
  const { mode } = props
  const [state,] = useProfileState()
  const { profile } = state
  const [educations, setEducations] = useState<Education[]>(profile?.educations ?? [])
  const [showModal, setShowModal] = useState(false)
  const [, dispatchLoadingState] = useLoadingState()
  const [doneDisabled, setDoneDisabled] = useState(false)

  const initialItemMod: ItemModProp<Education> = {
    item: {
      degreeName: ``,
      fieldOfStudy: ``,
      schoolName: ``,
      dateRange: {
        monthFrom: 0,
        yearFrom: currentYear,
        toPresent: false,
        monthTo: 0,
        yearTo: currentYear
      }
    },
    onOk: async (item, index, cleanups = []) => {
      setShowModal(false)
      try {
        const newEducations = await addItemToArray(educations)(item, index)
        const updateFunc = async () => await updateEducations(newEducations, educations)
        await updateWrapper(dispatchLoadingState, updateFunc)
        setEducations(newEducations)
        Promise.all(cleanups.map(async (c) => await c()))
      }
      catch (err) {
        console.error(err)
        message.error(`Something went wrong...`)
      }
    },
    onCancel: async () => { setShowModal(false) },
    mode: `add`,
    index: undefined
  }

  const [itemMod, setItemMod] = useState(initialItemMod)
  const shouldUseDragHandle = useIsMobileDevice()



  const onSortEnd = (props: { oldIndex: number, newIndex: number }) => {
    setEducations(arrayMove(educations, props.oldIndex, props.newIndex))
  };


  return (<div className={styles.root} id={id}>
    <div className={`card ${styles.cardBody}`}>
      <div className="card__header margin--auto">
        <h3 style={{ fontSize: 36, marginTop: 0, marginBottom: 0 }}>Education</h3>
      </div>
      {
        educations.length ?
          <div className={` ${styles.innerCardBody}`}>
            {
              function () {
                const SortableEducationContainer = SortableContainer(() => {
                  return <ul style={{ padding: 0 }}>
                    {educations.map((e, i) => {
                      const { degreeName, fieldOfStudy, activities, grade, url, schoolName, schoolLogo, description, dateRange } = e
                      const schoolLogoFull = schoolLogo ?? defaultInsititution
                      return (
                        <div key={i} className={`card__body ${styles.educationElem}`}>
                          <hr style={{ marginTop: 12 }} />
                          {shouldUseDragHandle && mode === 'edit' && <DragHandle></DragHandle>}

                          {
                            schoolLogo &&
                            <div className={`avatar avatar--vertical ${styles.avatarMobileLogo} margin--auto`}>
                              {url ?
                                <a href={url} target="_blank" rel="noopener noreferrer" >
                                  <img className="hoverLarge margin--auto" src={schoolLogoFull} alt={schoolName} />
                                </a>
                                :
                                <img src={schoolLogoFull} alt={schoolLogo} className={`margin--auto`} />
                              }</div>
                          }
                          <div className="avatar">
                            {
                              <div className={`avatar ${styles.avatarLogo}`}>
                                {url ?
                                  <a href={url} target="_blank" rel="noopener noreferrer" style={{ maxWidth: '80%', maxHeight: '80%', margin: 'auto' }} >
                                    <img className="hoverLarge" src={schoolLogoFull} alt={schoolName} />
                                  </a>
                                  :
                                  <img src={schoolLogoFull} alt={schoolName} style={{ maxWidth: '80%', maxHeight: '80%', margin: 'auto' }} />
                                } </div>
                            }
                            <div className={`avatar__intro ${styles.avatarContent}`} style={{ textAlign: `left` }}>
                              <h4 className="avatar__name" style={{ fontSize: 20, marginBottom: 4 }}>{schoolName}</h4>
                              <div className={styles.centerOnMobile}>
                                {
                                  joinStringsWithDot(
                                    [
                                      degreeName,
                                      fieldOfStudy,
                                      yearRangeDisplay(dateRange)
                                    ]

                                  )
                                }

                              </div>
                              {(grade && grade.length > 0) && <p>Grade: {grade}</p>}
                              {
                                (description && description.length > 0) &&
                                <div className={styles.jobContent}>
                                  <InnerHTML html={replaceNewlinesWithBr(description)} />
                                </div>
                              }
                              {
                                (activities && activities.length > 0) &&
                                <div className={styles.jobContent}>
                                  {<h4 style={{ marginBottom: 2, marginTop: 8 }}>Activities</h4>}
                                  <InnerHTML html={replaceNewlinesWithBr(activities)} />
                                </div>
                              }
                            </div>
                          </div> {
                            mode === `edit` &&
                            <IndivEditButtons itemName={itemName} editAction={async () => {
                              const newItemMod: ItemModProp<Education> = {
                                ...initialItemMod,
                                item: e,
                                mode: `edit`,
                                index: i,
                                onDelete: async (cleanups = []) => {
                                  try {
                                    setShowModal(false)
                                    const newEducations = await deleteItemFromArray(i, educations)()
                                    const updateFunc = async () => await updateEducations(newEducations, educations)
                                    await updateWrapper(dispatchLoadingState, updateFunc)
                                    setEducations(newEducations)
                                    Promise.all(cleanups.map(async (c) => await c()))
                                  }
                                  catch (err) {
                                    console.error(err)
                                    message.error(`Something went wrong...`)
                                  }
                                }
                              }
                              setItemMod(newItemMod)
                              setShowModal(true)
                            }} />
                          }
                        </div>)

                    }).map((edu, i) => {

                      const SortableEducation = SortableElement(() => <div>
                        {edu}
                      </div>)
                      return <SortableEducation disabled={mode !== 'edit'} key={`${i}-sortable`} index={i}></SortableEducation>
                    })
                    }
                  </ul>
                })
                return <SortableEducationContainer useWindowAsScrollContainer useDragHandle={shouldUseDragHandle} onSortEnd={onSortEnd} distance={2} axis="y"></SortableEducationContainer>

              }()
            }
            {educations.length > 1 && mode === `edit` && <p>
              {
                shouldUseDragHandle
                  ?
                  `Drag an ${itemName} item with the drag handle to change the order`
                  :
                  `Drag an ${itemName} item to change the order`
              }
            </p>}
          </div>
          :
          <div className={`card__body ${styles.innerCardBody}`}>
            <hr />
            <p>Add some {pluralItemName} and they will appear here!</p>
          </div>
      }


      {
        mode === `view` &&
        <MainEditButton pluralItemName={pluralItemName} targetUrl={`/${endpoints.profile}/edit/education`} />
      }
      {
        mode === `edit` &&
        <div>
          <AddNewButton itemName={itemName} action={async () => {
            if (educations.length < 10) {
              setItemMod(initialItemMod)
              setShowModal(true)
            }
            else {
              message.error(`You can only add up to 10 ${pluralItemName}`)
            }
          }} />
          <div>
            <DoneButton disabled={doneDisabled} action={async () => {
              /// save the ordering
              setDoneDisabled(true)
              try {
                const updateFunc = async () => await updateEducations(educations, profile?.educations ?? [])
                await updateWrapper(dispatchLoadingState, updateFunc)
                history.push(`/${endpoints.profile}`)
              }
              catch (err) {
                console.error(err)
                message.error(`Something went wrong...`)
              }
              setDoneDisabled(false)
            }} />
          </div>
        </div>
      }
    </div>
    <AddEditDeleteModal itemModProp={itemMod} show={showModal} uid={state.firebaseCurrentUserId ?? `` /* the fallback should never happen */} />
  </div >)
}

Educations.defaultProps = defaultProfileComponentProps
