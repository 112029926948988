import React from 'react'
import { ProfileComponentProps, defaultProfileComponentProps } from '../common'
import styles from '../common.module.css'
import defaultProfile from '../../../../assets/defaultProfile.png'
import { MainEditButton } from '../Subcomponents/EditButtons'
import { useProfileState } from '../../../../contexts/profileState'
import { Avatar } from 'antd'
import { replaceNewlinesWithBr } from '../../../../utils/utils'
import { EditCoreProfile } from './Form'
import InnerHTML from 'dangerously-set-html-content'

// const testImg = `https://images.pexels.com/photos/853199/pexels-photo-853199.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260`

export const pluralItemName = `Info`
const id = "CoreProfile"


export const CoreProfile = (props: ProfileComponentProps) => {
  const { mode } = props
  const [state,] = useProfileState()

  if (mode === `edit`) {
    return <EditCoreProfile />
  }

  const { profile } = state
  // console.log(profile)
  return (
    <div className={styles.root} id={id}>
      <div className={`card ${styles.cardBody}`}>
        <div className={`card__body ${styles.innerCardBody}`}>
          <Avatar size={150} src={profile?.profilePic ?? defaultProfile} />
          <div style={{ marginBottom: 0 }}>
            <h3 style={{ marginTop: 24 }}>{profile?.fullName ?? `Your Name`}</h3>
            <p>{profile?.headline ?? `Headline`}</p>
            <p>{profile?.location ?? `Location`}</p>
            <span>
              {
                (profile?.summary && profile.summary.length > 0) ?
                  <div className={styles.summaryPad} style={{ marginTop: 24, textAlign: `left` }}>
                    <InnerHTML style={{ marginBottom: `var(--ifm-paragraph-margin-bottom)` }} html={replaceNewlinesWithBr(profile.summary)} />
                  </div>
                  : <p>Summary</p>
              }
            </span>
            {
              profile?.backgroundPic &&
              <div style={{ marginTop: 32 }}>
                <h4>Background Image</h4>
                <img style={{ maxWidth: `80%` }} src={profile?.backgroundPic} alt="Background" />
              </div>

            }
            {
              mode === `view` &&
              <MainEditButton pluralItemName={pluralItemName} targetUrl="/profile/edit/info" />
            }

          </div>
        </div>
      </div>
    </div>
  )
}
CoreProfile.defaultProps = defaultProfileComponentProps