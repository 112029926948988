
import * as React from 'react'
import { Navbar, Nav } from 'react-bootstrap'
import logo from '../../../assets/logo192.png'
import styles from './Header.module.css'
import { LinkContainer } from 'react-router-bootstrap'
import defaultProfile from '../../../assets/defaultProfile.png'
import { Link } from 'react-router-dom'
import { useProfileState } from '../../../contexts/profileState'
import { Avatar } from 'antd'
import { endpoints } from '../../../consts'
import { history } from '../../../utils/history'

const { useState, useEffect } = React
const Header = () => {
  const [state,] = useProfileState()
  const profilePic = state?.profile?.profilePic
  const [location, setLocation] = useState(history.location.pathname.toString())

  useEffect(() => {
    history.listen(loc => {
      setLocation(loc.pathname.toString())
    })
  })

  const getNavElems = () => {
    if (state.firebaseCurrentUserId) {
      return (
        <Nav>
          <div className="dropdown dropdown--hoverable dropdown--right">
            <span>
              <Avatar
                className={`hoverLarge12`}
                src={profilePic ?? defaultProfile}
              />
            </span>
            <ul className="dropdown__menu">
              <li>
                <Link to={`/${endpoints.profile}`} style={{ color: 'white' }}>
                  <span className="dropdown__link">
                    Profile
                  </span>
                </Link>
              </li>

              <li>
                <Link to={`/${endpoints.dashboard}`} style={{ color: 'white' }}>
                  <span className="dropdown__link">
                    Dashboard
                  </span>
                </Link>
              </li>
              <li>
                <Link to={"/logout"} style={{ color: 'white' }}>
                  <span className="dropdown__link">
                    Logout
                  </span>
                </Link>
              </li>
            </ul>
          </div>
        </Nav>
      )
    }
    return (

      <Nav>
        <LinkContainer to={"/login"}>
          <Nav.Link>Sign In</Nav.Link>
        </LinkContainer>
      </Nav>
    )
  }

  return (
    <Navbar sticky="top" bg="dark" variant="dark" expand="sm">
      <Link to={"/"}>
        <Navbar.Brand className={styles.logoWrapper}>
          <img src={logo} alt="TuneApp" className={styles.logo} />
          <span className={styles.logoText}>Portosaurus</span>
        </Navbar.Brand>
      </Link>
      {
        state.firebaseCurrentUserId &&

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">

            <LinkContainer to={`/${endpoints.profile}`}>
              <Nav.Link active={location === `/${endpoints.profile}`}>Profile</Nav.Link>
            </LinkContainer>
            <LinkContainer to={`/${endpoints.dashboard}`}>
              <Nav.Link active={location === `/${endpoints.dashboard}`}>Dashboard</Nav.Link>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      }
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      {/* <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end"> */}
      <Nav className="mr-auto" />
      {getNavElems()}
      {/* </Navbar.Collapse> */}
    </Navbar>
  )
}

export default Header