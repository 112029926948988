import React, { useState } from "react";
import {
  ProfileComponentProps,
  defaultProfileComponentProps,
  addItemToArray,
  ItemModProp,
  deleteItemFromArray,
} from "../common";
import styles from "../common.module.css";
import {
  joinStringsWithDot,
  replaceNewlinesWithBr,
} from "../../../../utils/utils";
import {
  MainEditButton,
  AddNewButton,
  IndivEditButtons,
  DoneButton,
} from "../Subcomponents/EditButtons";
import { useProfileState } from "../../../../contexts/profileState";
import { useLoadingState } from "../../../../contexts/loadingState";
import { monthYearDateDisplay } from "../../../../utils/datetime";
import {
  updateAwards,
  updateWrapper,
} from "../../../../utils/firebase/profile";
import { message } from "antd";
import { currentYear, endpoints } from "../../../../consts";
import { history } from "../../../../utils/history";

import { AddEditDeleteModal } from "./Form";
import { SortableElement, SortableContainer } from "react-sortable-hoc";
import arrayMove from "array-move";
import DragHandle from "../../../../components/DragHandle/DragHandle";
import useIsMobileDevice from "../../../../utils/useIsMobileDevice";
import InnerHTML from "dangerously-set-html-content";
import { capitalize } from "lodash";

export const itemName = `award`;
export const pluralItemName = `awards`;
export const id = itemName;

export const Awards = (props: ProfileComponentProps) => {
  const { mode } = props;
  const [state] = useProfileState();
  const { profile } = state;
  const [awards, setAwards] = useState<Award[]>(profile?.awards ?? []);
  const [showModal, setShowModal] = useState(false);
  const [, dispatchLoadingState] = useLoadingState();
  const [doneDisabled, setDoneDisabled] = useState(false);

  const shouldUseDragHandle = useIsMobileDevice();

  const initialItemMod: ItemModProp<Award> = {
    item: {
      name: ``,
      issuer: ``,
      date: {
        month: 0,
        year: currentYear,
      },
      description: ``,
    },
    onOk: async (item, index) => {
      try {
        setShowModal(false);
        const newAwards = await addItemToArray(awards)(item, index);
        const updateFunc = async () => await updateAwards(newAwards, awards);
        await updateWrapper(dispatchLoadingState, updateFunc);
        setAwards(newAwards);
      } catch (err) {
        console.error(err);
        message.error(`Something went wrong...`);
      }
    },
    onCancel: async () => {
      setShowModal(false);
    },
    mode: `add`,
    index: undefined,
  };

  const [itemMod, setItemMod] = useState(initialItemMod);

  const onSortEnd = (props: { oldIndex: number; newIndex: number }) => {
    setAwards(arrayMove(awards, props.oldIndex, props.newIndex));
  };

  return (
    <div className={styles.root} id={id}>
      <div className={`card ${styles.cardBody}`}>
        <div className="card__header margin--auto">
          <h3 style={{ fontSize: 36, marginTop: 0, marginBottom: 0 }}>
            {capitalize(pluralItemName)}
          </h3>
        </div>
        {awards.length ? (
          <div className={`${styles.innerCardBody}`}>
            {(function () {
              const SortableJobPosContainer = SortableContainer(() => {
                return (
                  <ul style={{ padding: 0 }}>
                    {awards.map((a, i) => {
                      const SortableJobPos = SortableElement(() => {
                        const { name, issuer, date, description } = a;
                        const { month, year } = date;
                        const displayedDate = monthYearDateDisplay(month, year);
                        return (
                          <div
                            key={i}
                            className={`card__body ${styles.awardElem}`}
                          >
                            <hr style={{ marginTop: 12 }} />
                            {shouldUseDragHandle && mode === "edit" && (
                              <DragHandle></DragHandle>
                            )}
                            <div className="avatar">
                              <div
                                className={`avatar__intro ${styles.avatarContent}`}
                                style={{ marginTop: 0 }}
                              >
                                <h4
                                  className="mobile-center-full-left avatar__name"
                                  style={{ fontSize: 20, marginBottom: 4 }}
                                >
                                  {name}
                                </h4>
                                <div className={styles.centerOnMobile}>
                                  {joinStringsWithDot([issuer, displayedDate])}
                                </div>
                                <div className={styles.jobContent}>
                                  <InnerHTML
                                    html={replaceNewlinesWithBr(
                                      description ?? ""
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                            {mode === `edit` && (
                              <IndivEditButtons
                                itemName={itemName}
                                editAction={async () => {
                                  const newItemMod: ItemModProp<Award> = {
                                    ...initialItemMod,
                                    item: a,
                                    mode: `edit`,
                                    index: i,
                                    onDelete: async () => {
                                      try {
                                        setShowModal(false);
                                        const newAwards: Award[] =
                                          await deleteItemFromArray(
                                            i,
                                            awards
                                          )();
                                        const updateFunc = async () =>
                                          await updateAwards(newAwards, awards);
                                        await updateWrapper(
                                          dispatchLoadingState,
                                          updateFunc
                                        );
                                        setAwards(newAwards);
                                      } catch (err) {
                                        console.error(err);
                                        message.error(
                                          `Something went wrong...`
                                        );
                                      }
                                    },
                                  };
                                  setItemMod(newItemMod);
                                  setShowModal(true);
                                }}
                              />
                            )}
                          </div>
                        );
                      });
                      return (
                        <SortableJobPos
                          disabled={mode !== `edit`}
                          key={`${i}-sortable`}
                          index={i}
                        ></SortableJobPos>
                      );
                    })}
                  </ul>
                );
              });
              return (
                <SortableJobPosContainer
                  useWindowAsScrollContainer
                  useDragHandle={shouldUseDragHandle}
                  distance={2}
                  axis="y"
                  onSortEnd={onSortEnd}
                ></SortableJobPosContainer>
              );
            })()}
            {awards.length > 1 && mode === `edit` && (
              <p>
                {shouldUseDragHandle
                  ? `Drag an ${itemName} item with the drag handle to change the order`
                  : `Drag an ${itemName} item to change the order`}
              </p>
            )}
          </div>
        ) : (
          <div className={`card__body ${styles.innerCardBody}`}>
            <hr />
            <p>Add some {pluralItemName} and it will appear here!</p>
          </div>
        )}

        {mode === `view` && (
          <MainEditButton
            pluralItemName={pluralItemName}
            targetUrl={`/${endpoints.profile}/edit/${pluralItemName}`}
          />
        )}
        {mode === `edit` && (
          <div>
            <AddNewButton
              itemName={itemName}
              action={async () => {
                if (awards.length < 10) {
                  setItemMod(initialItemMod);
                  setShowModal(true);
                } else {
                  message.error(`You can only add up to 10 ${pluralItemName}`);
                }
              }}
            />
            <div>
              <DoneButton
                disabled={doneDisabled}
                action={async () => {
                  /// save the ordering
                  setDoneDisabled(true);
                  try {
                    const updateFunc = async () =>
                      await updateAwards(awards, profile?.awards ?? []);
                    await updateWrapper(dispatchLoadingState, updateFunc);
                    history.push(`/${endpoints.profile}`);
                  } catch (err) {
                    console.error(err);
                    message.error(`Something went wrong...`);
                  }
                  setDoneDisabled(false);
                }}
              />
            </div>
          </div>
        )}
      </div>
      <AddEditDeleteModal itemModProp={itemMod} show={showModal} />
    </div>
  );
};

Awards.defaultProps = defaultProfileComponentProps;
