
import { getDownloadUrl, downloadUrlToStorageReference } from "./storage"
/// this is useless
// export const userProfileToUserDocFirestore = async (userProfile: UserProfile): Promise<UserDocFirestore> => {
//     const {
//         fullName,
//         email,
//         headline,
//         location,
//         summary,


//         profilePic,
//         backgroundPic,
//         educations,
//         jobPositions,
//         projects,
//         contacts,
//         skills } = userProfile

//     return {
//         fullName,
//         email,
//         headline,
//         location,
//         summary,

//         profilePic,
//         backgroundPic,
//         educations,
//         jobPositions,
//         projects,
//         contacts,
//         skills,

//         /*
//           TODO:- 
//             generatedGithubPortfolioRepoName
//             fullPortfolioBuildQueueJobId
//         */
//     }
// }

const educationFirestoreToEducation = async (educationFirestore: EducationFirestore): Promise<Education> => {
    const { schoolLogo } = educationFirestore
    return {
        ...educationFirestore,
        schoolLogo: schoolLogo && await getDownloadUrl(schoolLogo)
    }
}

export const educationToEducationFirestore = async (education: Education): Promise<EducationFirestore> => {
    const { schoolLogo } = education
    return {
        ...education,
        schoolLogo: schoolLogo && await downloadUrlToStorageReference(schoolLogo)
    }
}

const jobPositionFirestoreToJobPosition = async (jobPositionFirestore: JobPositionFirestore): Promise<JobPosition> => {
    const { companyLogo } = jobPositionFirestore
    return {
        ...jobPositionFirestore,
        companyLogo: companyLogo && await getDownloadUrl(companyLogo)
    }
}

export const jobPositionToJobPositionFirestore = async (jobPosition: JobPosition): Promise<JobPositionFirestore> => {
    const { companyLogo } = jobPosition
    return {
        ...jobPosition,
        companyLogo: companyLogo && await downloadUrlToStorageReference(companyLogo)
    }
}

const projectFirestoreToProject = async (projectFirestore: ProjectFirestore): Promise<Project> => {
    const { projectImg } = projectFirestore
    return {
        ...projectFirestore,
        projectImg: projectImg && await getDownloadUrl(projectImg)
    }
}

export const projectToProjectFirestore = async (project: Project): Promise<ProjectFirestore> => {
    const { projectImg } = project
    return {
        ...project,
        projectImg: projectImg && await downloadUrlToStorageReference(projectImg)
    }
}

export const userDocFirestoreToUserPreferences = (userDocFirestore: UserDocFirestore): UserPreferences => {
    const { portfolioSectionOrder } = userDocFirestore
    return { portfolioSectionOrder }
}

export const userDocFirestoreToUserProfile = async (userDocProfile: UserDocFirestore): Promise<UserProfile> => {
    const {
        fullName,
        email,
        headline,
        location,
        summary,

        profilePic,
        backgroundPic,
        educations,
        jobPositions,
        projects,
        contacts,
        skills,
        awards } = userDocProfile

    const profilePicUrl = profilePic && await getDownloadUrl(profilePic)
    const backgroundPicUrl = backgroundPic && await getDownloadUrl(backgroundPic)
    const educationsClient = educations && await Promise.all(educations.map(educationFirestoreToEducation))
    const jobPositionsClient = jobPositions && await Promise.all(jobPositions.map(jobPositionFirestoreToJobPosition))
    const projectsClient = projects && await Promise.all(projects.map(projectFirestoreToProject))

    return {
        fullName,
        email,
        headline,
        location,
        summary,

        profilePic: profilePicUrl,
        backgroundPic: backgroundPicUrl,
        educations: educationsClient,
        jobPositions: jobPositionsClient,
        projects: projectsClient,
        contacts,
        skills,
        awards
    }
}