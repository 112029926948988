import { storage } from './firebase'
import { v4 as uuidv4 } from 'uuid'

export const downloadUrlToStorageReference = async (downloadURL: string) => {
    try {
        return storage.refFromURL(downloadURL).fullPath
    }
    catch (err) {
        console.error(err)
    }
}

export const downloadUrlToStorageReferenceSync = (downloadURL: string) => {
    return storage.refFromURL(downloadURL).fullPath
}

export const uploadFile = async (pathRef: FirebaseStorageReference, file: File | Blob) => {
    try {
        const storageRef = storage.ref()
        const imgPathRef = storageRef.child(pathRef);
        await imgPathRef.put(file);
    }
    catch (err) {
        console.error(err)
    }
}

export const deleteFile = async (pathRef: FirebaseStorageReference) => {
    try {
        const storageRef = storage.ref(pathRef)
        await storageRef.delete()
    }
    catch (err) {
        console.error(err)
    }
}

export const deleteFileDownloadUrl = async (downloadURL: string) => {
    try {
        await deleteFile(downloadUrlToStorageReferenceSync(downloadURL))
    }
    catch (err) {
        console.error(err)
    }
}

export const getDownloadUrl = async (ref: FirebaseStorageReference) => {
    try {
        const uri = await storage.ref(ref).getDownloadURL()
        return uri as string
    }
    catch (err) {
        console.error(err)
    }
}

export const generateImageUploadDir = (key: string, uid: string) => {
    return `images/${uid}/${key}/${uuidv4()}`
}