import React, { useState } from 'react'
import { ItemModProp, NextImageType, getNextImageDownloadUrlAndCleanup, NextImageDownloadUrlAndCleanup, getImageCleanUp } from '../common'
import { SaveChangesButton, DeleteButton, DiscardChangesButton } from '../Subcomponents/EditButtons'
import { Modal, message } from 'antd'
import { Formik } from 'formik'
import * as yup from 'yup'
import { Form, Col } from 'react-bootstrap'
import { ImageUpload } from '../Subcomponents/ImageUpload'
import { removeUndefined } from '../../../../utils/firebase/profile'
import { id, itemName } from './Educations'
import { yupYearValidator } from '../../../../utils/datetime'

export const AddEditDeleteModal = (props: { itemModProp: ItemModProp<Education>; show: boolean; uid: string }) => {

  const [disabled, setDisabled] = useState(false)
  const { show, itemModProp, uid } = props
  const { item, onOk, onCancel, mode, index, onDelete } = itemModProp
  const addOrEdit = mode === `add` ? `Add` : `Edit`
  const [nextSchoolLogo, setNextSchoolLogo] = useState<NextImageType>({
    file: undefined,
    update: false
  })

  type FormType = Omit<Education, "description" | "activities"> & {
    description: string;
    activities: string;
  }

  const initialValues: FormType = {
    ...item,
    description: item.description ?? "",
    activities: item.activities ?? ""
  }


  const toActualTypeAndCleanups = async (x: FormType): Promise<{ education: Education, cleanups: (() => Promise<void>)[] }> => {
    let cleanups: (() => Promise<void>)[] = []

    const nextSchoolLogoDownloadUrlAndCleanup: NextImageDownloadUrlAndCleanup = await getNextImageDownloadUrlAndCleanup(id, x.schoolLogo, nextSchoolLogo, uid)
    const schoolLogo = nextSchoolLogoDownloadUrlAndCleanup.downloadUrl
    if (nextSchoolLogoDownloadUrlAndCleanup.cleanup) {
      cleanups.push(nextSchoolLogoDownloadUrlAndCleanup.cleanup)
    }
    const _education: Education = {
      ...x,
      schoolLogo
    }

    const education = (await removeUndefined(_education)) as Education
    return {
      education,
      cleanups
    }
  }

  const schema = yup.object({
    degreeName: yup.string()
      .max(128, `Must be 128 characters or fewer`),
    fieldOfStudy: yup.string()
      .max(128, `Must be 128 characters or fewer`),
    schoolName: yup.string()
      .max(128, `Must be 128 characters or fewer`)
      .required(`Required`),
    schoolLogo: yup.mixed(),
    // .test(`file-size`, `File size must be less than 1MB`, (value: File) => value.size <= 1024 * 1024)
    // .test(`file-type`, `Unsupported File format`, (value: File) => value.type.substring(0, 5) === `image`),
    url: yup.string()
      .max(128, `Must be 128 characters or fewer`)
      .url(`Please enter a valid URL, e.g. https://www.example.com`),
    dateRange: yup.object({
      yearFrom: yupYearValidator
        .required(`Required`),
      yearTo: yupYearValidator
        .required(`Required`)
        .test(`date-test`, `End year cannot be before start year`, function () {
          const { yearFrom, yearTo } = this.parent
          return (yearFrom === yearTo) || yearFrom < yearTo
        })
    }),
    grade: yup.string()
      .max(128, `Must be 128 characters or fewer`),
    description: yup.string()
      .max(4096, `Must be 4096 characters or fewer`),
    activities: yup.string()
      .max(4096, `Must be 4096 characters or fewer`),
  })

  return (
    <Modal
      title={`${addOrEdit} ${itemName}`}
      visible={show}
      centered
      footer={null}
      onCancel={onCancel}
      maskClosable={false}
      closable={false}
      keyboard={false}
      destroyOnClose
      zIndex={1021}
    >
      <Formik
        validationSchema={schema}
        onSubmit={async (vals, { resetForm }) => {
          setDisabled(true)
          try {
            const { education, cleanups } = await toActualTypeAndCleanups(vals)
            onOk(education, index, cleanups)
          }
          catch (err) {
            console.error(err)
            message.error(`Something went wrong`)
          }
          setDisabled(false)
          resetForm()
        }}
        enableReinitialize
        initialValues={initialValues}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          isValid,
          errors,
        }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group controlId="education">

                <Form.Label>School Name*</Form.Label>
                <Form.Control
                  type="text"
                  name="schoolName"
                  value={values.schoolName}
                  placeholder="Enter school name"
                  onChange={handleChange}
                  isInvalid={!!errors.schoolName}
                  isValid={touched.schoolName && !errors.schoolName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.schoolName}
                </Form.Control.Feedback>


                <Form.Label>School Logo</Form.Label>
                <ImageUpload
                  originalDownloadUrl={values.schoolLogo}
                  setFile={async (file: File | undefined) => {
                    setNextSchoolLogo({
                      file,
                      update: true
                    })
                  }}
                  size="sm"
                  setSubmitDisabled={async (disabled: boolean) => setDisabled(disabled)}
                />

                <Form.Control.Feedback type="invalid">
                  {errors.fieldOfStudy}
                </Form.Control.Feedback>

                <Form.Row>
                  <Col>
                    <Form.Label>Start Year*</Form.Label>
                    <Form.Control
                      type="number"
                      name="dateRange.yearFrom"
                      value={values.dateRange.yearFrom}
                      placeholder="Year"
                      onChange={handleChange}
                      isInvalid={!!errors.dateRange?.yearFrom}
                      isValid={touched.dateRange?.yearFrom && !errors.dateRange?.yearFrom}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.dateRange?.yearFrom}
                    </Form.Control.Feedback>
                  </Col>
                  <Col>
                    <Form.Label>End Year (or expected)*</Form.Label>
                    <Form.Control
                      type="number"
                      name="dateRange.yearTo"
                      value={values.dateRange.yearTo}
                      placeholder="Year"
                      onChange={handleChange}
                      isInvalid={!!errors.dateRange?.yearTo}
                      isValid={touched.dateRange?.yearTo && !errors.dateRange?.yearTo}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.dateRange?.yearTo}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Row>

                <Form.Label>Degree Name</Form.Label>
                <Form.Control
                  type="text"
                  name="degreeName"
                  value={values.degreeName}
                  placeholder="Enter degree name"
                  onChange={handleChange}
                  isInvalid={!!errors.degreeName}
                  isValid={touched.degreeName && !errors.degreeName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.degreeName}
                </Form.Control.Feedback>

                <Form.Label>Field of Study</Form.Label>
                <Form.Control
                  type="text"
                  name="fieldOfStudy"
                  value={values.fieldOfStudy}
                  placeholder="Enter field of study"
                  onChange={handleChange}
                  isInvalid={!!errors.fieldOfStudy}
                  isValid={touched.fieldOfStudy && !errors.fieldOfStudy}
                />
                <Form.Label>School URL</Form.Label>
                <Form.Control
                  type="text"
                  name="url"
                  value={values.url}
                  placeholder="Enter School URL"
                  onChange={handleChange}
                  isInvalid={!!errors.url}
                  isValid={touched.url && !errors.url}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.url}
                </Form.Control.Feedback>



                <Form.Label>Grade</Form.Label>
                <Form.Control
                  type="text"
                  name="grade"
                  value={values.grade}
                  placeholder="Enter grade"
                  onChange={handleChange}
                  isInvalid={!!errors.grade}
                  isValid={touched.grade && !errors.grade}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.grade}
                </Form.Control.Feedback>


                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  name="description"
                  value={values.description}
                  placeholder="Enter Description"
                  onChange={handleChange}
                  isInvalid={!!errors.description}
                  isValid={touched.description && !errors.description}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.description}
                </Form.Control.Feedback>


                <Form.Label>Activities</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  name="activities"
                  value={values.activities}
                  placeholder="Enter Activities"
                  onChange={handleChange}
                  isInvalid={!!errors.activities}
                  isValid={touched.activities && !errors.activities}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.activities}
                </Form.Control.Feedback>

              </Form.Group>
              {
                mode === `edit` &&
                <div style={{ textAlign: `center` }}>
                  <DeleteButton onDelete={async () => {
                    /// we delete the images after it's set
                    if (onDelete) {
                      let cleanups: (() => Promise<void>)[] = []
                      if (item.schoolLogo) {
                        cleanups.push(await getImageCleanUp(item.schoolLogo))
                      }
                      await onDelete(cleanups)
                    }
                  }
                  }
                    itemName={itemName} />
                </div>
              }
              <div style={{ textAlign: `center` }}>
                <DiscardChangesButton onDiscard={onCancel} />
                <SaveChangesButton type="submit" disabled={!touched || !isValid || disabled} />
              </div>
            </Form>
          )}
      </Formik>
    </Modal>
  )
}
