import React from 'react'
import PortosaurusFirebaseAuth from './Components/FirebaseAuth/FirebaseAuth'
import { Link } from 'react-router-dom'
import styles from './Login.module.css'
import { endpoints } from '../../consts'

export const Login = () => {
    return (
        <div className={`card ${styles.root}`}>
            <div className="card__header" style={{ marginBottom: 32, marginTop: 16 }}>
                <h3 style={{ fontSize: `2rem` }}>Sign in</h3>
            </div>
            <div className="card__body">
                <PortosaurusFirebaseAuth />
            </div>
            <div className="card__footer" style={{ fontSize: `0.8rem`, marginTop: 32, marginBottom: 32 }}>
                By signing in, you agree to the <Link to={`/${endpoints.termsOfService}`}>Terms of Service</Link>
            </div>
        </div>

    )
}