import * as React from 'react'

import loading from '../../assets/loading.svg'
// import styles from './Loading.module.css'

type Props = {
    loadingTitle?: string;
    loadingText?: string | undefined;
}

const defaultProps: Props = {
    loadingTitle: `Loading...`,
    loadingText: undefined
}


const Loading: React.FC<Props> = (props: Props) => {
     return (<div style={{ textAlign: 'center' }}>
        <h1 style={{ paddingTop: 30, marginBottom: 0, fontSize: '3rem' }}>{props.loadingTitle}</h1>
        {
            props.loadingText && <div style={{ padding: 20, fontSize: '1.5rem' }}>{props.loadingText}</div>
        } 
        <img src={loading} alt="loading" className="loader" />
    </div>)

}
Loading.defaultProps = defaultProps

export default Loading

