import React from 'react'
import { deleteFileDownloadUrl, generateImageUploadDir, uploadFile, getDownloadUrl } from '../../../utils/firebase/storage'

/// for next image
export type NextImageType = {
    file: File | undefined
    update: boolean // whether a file should be updated / deleted
}

// none: show nothing after the data
// view: show the edit buttons
// edit: show the add buttons
export type ProfileComponentProps = {
    mode: `none` | `view` | `edit`
}

export const defaultProfileComponentProps = {
    mode: `view`
}

export const addItemToArraySt
    = <T>(arr: T[], setter: React.Dispatch<React.SetStateAction<T[]>>, comparator: (((a: T, b: T) => number) | undefined) = undefined) => async (item: T, index: number | undefined) => {
        let newArr = [...arr]
        if (index === undefined) newArr.push(item)
        else newArr[index] = item
        if (comparator) {
            newArr.sort(comparator)
        }
        setter(newArr)
    }

export const addItemToArray
    = <T>(arr: T[], comparator: (((a: T, b: T) => number) | undefined) = undefined) => async (item: T, index: number | undefined) => {
        let newArr = [...arr]
        if (index === undefined) newArr.push(item)
        else newArr[index] = item
        if (comparator) {
            newArr.sort(comparator)
        }
        return newArr
    }

export const deleteItemFromArraySt
    = <T>(index: number, arr: T[], setter: React.Dispatch<React.SetStateAction<T[]>>) => async () => {
        let newArr = [...arr]
        newArr.splice(index, 1)
        setter(newArr)
    }

export const deleteItemFromArray
    = <T>(index: number, arr: T[]) => async () => {
        let newArr = [...arr]
        newArr.splice(index, 1)
        return newArr
    }

/// Type for item modification, passed into theform
/// cleanups are to help image deletion, replacement
/// cleanups are run last after the ref updates to make sure updates `appear` atomic
export type ItemModProp<T> = {
    item: T
    onOk: (item: T, index: number | undefined, cleanups?: (() => Promise<void>)[]) => Promise<void>
    onCancel: () => Promise<void>
    onDelete?: (cleanups?: (() => Promise<void>)[]) => Promise<void>
    mode: `add` | `edit`
    index: number | undefined // undefined: new
}

/// when we replace an image
/// we need to make sure the deletion occurs *AFTER* (in cleanup)
/// the new image is uploaded and ref is updated in firestore
export type NextImageDownloadUrlAndCleanup = {
    downloadUrl?: DownloadUrl
    cleanup?: (() => Promise<void>)
}

export const getImageCleanUp = async (downloadUrl: DownloadUrl): Promise<(() => Promise<void>)> => {
    return async () => {
        // console.log(`deleting ${downloadUrl}`)
        await deleteFileDownloadUrl(downloadUrl)
    }
}

/// handles deleting and uploading images
/// images/uid/${key}/${uuid}/${fileName}
export const getNextImageDownloadUrlAndCleanup =
    async (key: string, oldDownloadUrl: DownloadUrl | undefined, nextImage: NextImageType, uid: string): Promise<NextImageDownloadUrlAndCleanup> => {
        let downloadUrl: DownloadUrl | undefined
        let cleanup: (() => Promise<void>) | undefined
        /// need to update
        if (nextImage.update) {
            if (oldDownloadUrl) {
                /// delete the old one if present
                cleanup = await getImageCleanUp(oldDownloadUrl)
            }
            if (nextImage.file) {
                /// upload the next one
                const uploadPathRef = `${generateImageUploadDir(key, uid)}/${nextImage.file.name}`
                await uploadFile(uploadPathRef, nextImage.file)
                const newDownloadUrl = await getDownloadUrl(uploadPathRef)
                downloadUrl = newDownloadUrl
            }
            else {
                downloadUrl = undefined
            }
        }
        else {
            downloadUrl = oldDownloadUrl
        }
        return {
            downloadUrl,
            cleanup
        }
    }