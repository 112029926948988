import React from 'react'
import styles from '../common.module.css'
import { Link } from 'react-router-dom'
import { Popconfirm } from 'antd'
import { history } from '../../../../utils/history'
import { endpoints } from '../../../../consts'


export const IndivEditButtons = (props: { itemName: string; editAction: () => Promise<void> }) => {
    const { itemName, editAction } = props
    return (
        <div>
            <span>
                <button onClick={editAction} className={`${styles.editBtnInner} button button--outline button--warning`}>Edit this {itemName}</button>
            </span>
            {/* <span>

                        <Popconfirm okText="Yes" cancelText="No" okButtonProps={{ type: `danger` }}  title={`Are you sure you want to delete this ${itemName}?`} onConfirm={deleteAction}>
                            <span className={`${styles.editBtnInner} button button--outline button--danger`}>Delete this {itemName}</span>
                        </Popconfirm>
                    </span> */}
        </div>
    )
}

export const MainEditButton = (props: { pluralItemName: string; targetUrl: string }) => {
    const { pluralItemName, targetUrl } = props
    return (
        <Link to={targetUrl}>
            <button className={`button button--warning button--outline ${styles.editBtn}`}>Edit {pluralItemName}</button>
        </Link>
    )
}

export const AddNewButton = (props: { itemName: string; action: () => Promise<void> }) => {
    const { itemName, action } = props
    return (
        <button onClick={action} className={`button button--info button--outline ${styles.editBtn}`}>Add new {itemName}</button>

    )
}

export const DiscardChangesButton = (props: { onDiscard: () => Promise<void>; popover: boolean }) => {
    const { onDiscard, popover } = props
    if (!popover) {
        return (
            <button onClick={onDiscard} className={`button button--danger ${styles.btmEditBtn}`}>Discard Changes</button>
        )
    }
    return (
        <Popconfirm cancelText="Cancel" okText="Discard" /* okButtonProps={{ type: `danger` }} */ title={`Your changes, if any, will be discarded`} onConfirm={onDiscard}>
            <button className={`button button--danger ${styles.btmEditBtn}`}>Discard Changes</button>
        </Popconfirm >
    )
}
DiscardChangesButton.defaultProps = { onDiscard: async () => history.push(`/${endpoints.profile}`), popover: true }


export const SaveChangesButton = (props: { action: any, disabled: boolean, type: `button` | `submit` | `reset` }) => {
    const { type, action, disabled } = props
    return (
        <button type={type} disabled={disabled} onClick={action} className={`button button--success ${styles.btmEditBtn}`}>Save Changes</button>
    )
}
SaveChangesButton.defaultProps = { action: () => { }, disabled: false, type: `button` }

export const DeleteButton = (props: { onDelete: (() => Promise<void>) | undefined, itemName: string }) => {
    const { onDelete, itemName } = props
    return (
        <Popconfirm okText="Yes" cancelText="No" /* okButtonProps={{ type: `danger` }} */ title={`Are you sure you want to delete this ${itemName}?`} onConfirm={onDelete}>
            <button className={`button button--danger ${styles.btmEditBtn}`}>Delete {itemName}</button>
        </Popconfirm >
    )


}

export const DoneButton = (props: { action: () => Promise<void>, disabled: boolean }) => {
    const { action, disabled } = props
    return (
        <button disabled={disabled} onClick={action} className={`button button--success ${styles.btmEditBtn}`}>Done</button>
    )
}
DoneButton.defaultProps = { disabled: false }
