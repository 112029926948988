/// endpoints for profile update
import { updateUser, updateCurrentUser } from "./firebase"
import { LoadingStateDispatchType } from "../../contexts/loadingState"
import { isEqual, pickBy } from 'lodash'
import { educationToEducationFirestore, jobPositionToJobPositionFirestore, projectToProjectFirestore } from "./shim"
import { downloadUrlToStorageReference } from "./storage"
import { message } from "antd"

export const getFirstLoginUserProfile = async (authResult: firebase.auth.UserCredential): Promise<UserProfile> => {
    const { user } = authResult
    if (user) {
        const { displayName, email } = user

        return {
            fullName: displayName ?? `Your Name`,
            email: `${email}`,
            contacts: [{ name: `Email`, url: `mailto:${email}` }]
        }
    }
    throw new Error(`Unable to get user profile!`)
}

export const setFirstLoginUserProfile = async (uid: FirestoreDocId, userProfile: Partial<UserProfile>) => {
    const payload = userProfile as Partial<UserDocFirestore>
    await updateUser(uid, payload)
}

export const removeUndefined = async (x: any) => {
    return pickBy(x, (val,) => !(val === undefined))
}

export const updateCoreProfile = async (profile: Partial<UserProfile>, oldProfile: UserProfile) => {
    const oldPartialProfile: Partial<UserProfile> = {
        profilePic: oldProfile.profilePic,
        fullName: oldProfile.fullName,
        headline: oldProfile.headline,
        location: oldProfile.headline,
        summary: oldProfile.summary,
        backgroundPic: oldProfile.backgroundPic
    }
    if (isEqual(profile, oldPartialProfile)) {
        // console.log(`skipped an update`)
        return
    }

    const { profilePic, backgroundPic } = profile

    const _payload: Partial<UserDocFirestore> = {
        ...profile,
        profilePic: profilePic && await downloadUrlToStorageReference(profilePic),
        backgroundPic: backgroundPic && await downloadUrlToStorageReference(backgroundPic)
    }

    const payload = await removeUndefined(_payload)

    await updateCurrentUser(payload)
}

export const updateJobPositions = async (_jobPositions: JobPosition[], oldJobPositions: JobPosition[]) => {
    if (isEqual(_jobPositions, oldJobPositions)) {
        // console.log(`skipped an update`)
        return
    }

    const jobPositions = await Promise.all(
        _jobPositions.map(async (j) => {
            const jf = await jobPositionToJobPositionFirestore(j)
            return (await removeUndefined(jf)) as JobPositionFirestore
        })
    )

    const payload: Partial<UserDocFirestore> = { jobPositions }
    await updateCurrentUser(payload)
}

export const updateEducations = async (_educations: Education[], oldEducations: Education[]) => {
    if (isEqual(_educations, oldEducations)) {
        // console.log(`skipped an update`)
        return
    }

    const educations = await Promise.all(
        _educations.map(async (e) => {
            const ef = await educationToEducationFirestore(e)
            return (await removeUndefined(ef)) as EducationFirestore
        })
    )

    const payload: Partial<UserDocFirestore> = { educations }
    await updateCurrentUser(payload)
}

export const updateProjects = async (_projects: Project[], oldProjects: Project[]) => {
    if (isEqual(_projects, oldProjects)) {
        // console.log(`skipped an update`)
        return
    }

    const projects = await Promise.all(
        _projects.map(async (p) => {
            const pf = await projectToProjectFirestore(p)
            return (await removeUndefined(pf)) as ProjectFirestore
        })
    )

    const payload: Partial<UserDocFirestore> = { projects }
    await updateCurrentUser(payload)
}

export const updateContacts = async (contacts: Contact[], oldContacts: Contact[]) => {
    if (isEqual(contacts, oldContacts)) {
        // console.log(`skipped an update`)
        return
    }
    const payload: Partial<UserDocFirestore> = { contacts }
    await updateCurrentUser(payload)
}

export const updateSkills = async (skills: Skill[], oldSkills: Skill[]) => {
    if (isEqual(skills, oldSkills)) {
        // console.log(`skipped an update`)
        return
    }
    const payload: Partial<UserDocFirestore> = { skills }
    await updateCurrentUser(payload)
}

export const updateAwards = async (awards: Award[], oldAwards: Award[]) => {
    if (isEqual(awards, oldAwards)) {
        return
    }
    const payload: Partial<UserDocFirestore> = { awards }
    await updateCurrentUser(payload)
}

export const updateWrapper = async (dispatchLoadingState: LoadingStateDispatchType, updatePromise: () => Promise<void>) => {
    dispatchLoadingState({ loading: true })
    try {
        await updatePromise()
    }
    catch (err) {
        console.error(err)
        message.error(`Something went wrong - we could not update your profile`)
    }
    /// this timeout is necessary so the component remains in loading state
    /// so that routing is not blocked by the Prompt
    // update 2021-03-16: Too many problems--rather not show Prompt because it constitutes FP
    // Keep the fake wait though.
    setTimeout(() => {
        dispatchLoadingState({ loading: false })
    }, 1000)
}
