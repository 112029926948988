import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { Modal, Popconfirm } from "antd";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  config,
  portfolioHostingIdText,
  portfolioHostingIdTextFirstCharUpper,
  portfolioSections,
} from "../../../consts";
import ChooseTheme from "../../ChooseTheme/ChooseTheme";
import {
  updateUser,
  updateCurrentUser,
} from "../../../utils/firebase/firebase";
import { useProfileState } from "../../../contexts/profileState";
import styles from "./PortfolioSettings.module.css";
import { SortableElement, SortableContainer } from "react-sortable-hoc";
import arrayMove from "array-move";
import { capitalisePhrase } from "../../../utils/utils";
import { animateScroll } from "react-scroll";

function getUserPreferredInitialPortfolioSectionOrder(
  portfolioSectionOrder: PortfolioSection[] | undefined
): PortfolioSection[] | undefined {
  if (portfolioSectionOrder === undefined) {
    return undefined;
  }
  // awards is newly added
  if (portfolioSectionOrder.includes("awards")) {
    return portfolioSectionOrder;
  }
  portfolioSectionOrder.push("awards");
  return portfolioSectionOrder;
}

export type PortfolioSettings = {
  repoName: string;
  // theme: PortfolioTheme
};
const scrollTop = () => {
  animateScroll.scrollToTop({ smooth: true, duration: 500 });
};

const PortfolioSettingsForm = (props: {
  initialRepoName?: string;
  isRepoNameAvailable: boolean;
  handleBuildPortfolioClick: (settings: PortfolioSettings) => void;
  handleUserStoppedTypingRepoName: (repoName: string) => void;
  isBuildButtonEnabled: boolean;
}) => {
  const [profileState] = useProfileState();
  const [buildDisabled, setBuildDisabled] = useState(false);

  const [isShowingChooseTheme, setIsShowingChooseTheme] = useState(false);

  const formik = useFormik({
    // enableReinitialize: true, //this messes things up
    initialValues: {
      repoName: props.initialRepoName,
    },
    onSubmit: (values) => {
      scrollTop();
      setBuildDisabled(true);
      if (values.repoName)
        props.handleBuildPortfolioClick({ repoName: values.repoName });
      setTimeout(() => setBuildDisabled(false), 3000);
    },
    validationSchema: yup.object({
      repoName: yup
        .string()
        .max(50, "Must be less than 50 characters")
        .min(1, "Must be greater than 1 character")
        .matches(/^[a-zA-Z0-9-_]*$/, "Invalid characters detected") //takes care of spaces //the server will just throw an error if it's an invalid github name.
        .test(
          `Is ${portfolioHostingIdText} available`,
          `This ${portfolioHostingIdText} name is not available`,
          () => props.isRepoNameAvailable
        )
        .required(`You must set a value for the ${portfolioHostingIdText}`),
    }),
  });

  const typingTimoutRef = useRef<NodeJS.Timeout | null>();
  const [canShowRepoNameValid, setCanShowRepoNameValid] = useState(false);
  useEffect(() => {
    formik.validateForm();
  }, [props.isRepoNameAvailable]);

  useEffect(() => {
    if (typingTimoutRef.current) {
      clearTimeout(typingTimoutRef.current);
    }
    typingTimoutRef.current = setTimeout(() => {
      typingTimoutRef.current = null;
      if (formik.values.repoName)
        props.handleUserStoppedTypingRepoName(formik.values.repoName);
    }, config.timeMillisForTypingToBeConsideredStopped);
  }, [formik.values.repoName]);

  function handleOpenChooseThemeClick() {
    setIsShowingChooseTheme(true);
  }

  function handleChooseThemeCloseClick() {
    setIsShowingChooseTheme(false);
  }

  async function handleThemeChosenClick(theme: PortfolioTheme) {
    // console.log("theme chosen:", theme)
    if (profileState.firebaseCurrentUserId) {
      await updateUser(profileState.firebaseCurrentUserId, {
        portfolioThemeName: theme.portfolioThemeName,
      });
    }
    setIsShowingChooseTheme(false);
  }

  function genPortfolioHostingUrl(): string {
    return `https://${(
      process.env.REACT_APP_GITHUB_PAGES_ORG ?? "portosaurus"
    ).toLowerCase()}.github.io/${formik.values.repoName}`;
  }

  return (
    <div>
      <div className={`card ${styles.cardRoot}`}>
        <div className="card__header">
          <h2>Settings</h2>
        </div>
        <div className={`card__body ${styles.cardBody}`}>
          <hr style={{ margin: 0, marginBottom: 8 }} />
          <Form noValidate onSubmit={formik.handleSubmit}>
            <Form.Label>
              <h3 style={{ margin: 0, fontWeight: 600, fontSize: `1.1rem` }}>
                {portfolioHostingIdTextFirstCharUpper}
              </h3>
            </Form.Label>
            <Form.Control
              style={{ marginBottom: 0 }}
              type="text"
              value={formik.values.repoName}
              placeholder={`Enter desired ${portfolioHostingIdText}`}
              onChange={(e) => formik.handleChange(e)}
              onBlur={formik.handleBlur}
              onFocus={() => setCanShowRepoNameValid(true)}
              name="repoName"
              isValid={
                canShowRepoNameValid &&
                props.isRepoNameAvailable &&
                !formik.errors.repoName
              }
              isInvalid={
                canShowRepoNameValid &&
                (!!formik.errors.repoName || !props.isRepoNameAvailable)
              }
            />
            <Form.Control.Feedback type="valid">
              Looks good! Your portfolio will be hosted at <br />
              {genPortfolioHostingUrl()}
            </Form.Control.Feedback>

            <Form.Control.Feedback type="invalid">
              {formik.errors.repoName}
            </Form.Control.Feedback>

            <div style={{ marginTop: 12 }}>
              <button
                className={`button button--secondary`}
                type="button"
                onClick={handleOpenChooseThemeClick}
              >
                Choose theme
              </button>
            </div>

            <div style={{ marginTop: 12 }} className={styles.sectionOrderCard}>
              <PortfolioSectionOrder
                initialOrder={getUserPreferredInitialPortfolioSectionOrder(
                  profileState?.userPreferences?.portfolioSectionOrder
                )}
              ></PortfolioSectionOrder>
            </div>

            <div style={{ marginTop: 12 }}>
              {/* some very hacky thing. So antd popover screws the style when the button is disabled (css scope clash),
            to mitigate that when it's disabled just dont render the popover choice */}
              {!props.isBuildButtonEnabled || buildDisabled ? (
                // disabled
                <button disabled={true} className={`button button--success`}>
                  Generate
                </button>
              ) : profileState.userDocFirestore?.repoNameCurrentlyInUse &&
                profileState.userDocFirestore?.repoNameCurrentlyInUse !==
                  formik.values.repoName ? (
                <Popconfirm
                  onConfirm={formik.submitForm}
                  title={`Changing the ${portfolioHostingIdText} will delete your old portfolio, and all links to it will no longer work. Are you sure you want to proceed? This action cannot be undone.`}
                  okText="Yes"
                  cancelText="No"
                >
                  <button className={`button button--success`}>Generate</button>
                </Popconfirm>
              ) : (
                <button
                  className={`button button--success`}
                  onClick={() => setCanShowRepoNameValid(true)}
                  type="submit"
                >
                  Generate
                </button>
              )}
            </div>

            <Modal
              visible={isShowingChooseTheme}
              // handleClose={onCancel}
              width="90vw"
              centered
              footer={null}
              onCancel={handleChooseThemeCloseClick}
              maskClosable={false}
              zIndex={1021}
            >
              <ChooseTheme handleChooseClick={handleThemeChosenClick} />
            </Modal>
          </Form>
        </div>
      </div>
    </div>
  );
};

function PortfolioSectionOrder(props: { initialOrder?: PortfolioSection[] }) {
  const [profileState] = useProfileState();
  const [sectionOrderList, setSectionOrderList] = useState(
    props.initialOrder ?? portfolioSections
  );

  const onSortEnd = async (props: { oldIndex: number; newIndex: number }) => {
    setSectionOrderList(
      arrayMove(sectionOrderList, props.oldIndex, props.newIndex)
    );
  };

  useEffect(() => {
    //without isFirebaseAuthReady it breaks the preview
    if (profileState.isFirebaseAuthReady && sectionOrderList) {
      updateCurrentUser({
        portfolioSectionOrder: sectionOrderList,
      });
    }
  }, [sectionOrderList]);

  return (
    <div
      className={`card`}
      style={{ border: `2px solid var(--ifm-color-gray-700)`, width: `100%` }}
    >
      <div className="card__header">
        <h3>Section order</h3>
      </div>
      <div className="card__body">
        {(function () {
          const SectionOrderContainer = SortableContainer(() => {
            const SortableItem = SortableElement(
              ({ value }: { value: PortfolioSection }) => (
                <div
                  className="card"
                  style={{
                    margin: `6px auto`,
                    backgroundColor: `var(--ifm-color-gray-800)`,
                  }}
                >
                  <div className="card__header" style={{ padding: 6 }}>
                    <p style={{ color: `white`, textAlign: `center` }}>
                      {capitalisePhrase(value)}
                    </p>
                  </div>
                </div>
              )
            );

            return (
              <ul style={{ padding: 0, width: `75%`, margin: `auto` }}>
                {sectionOrderList.map((el, i) => {
                  return (
                    <SortableItem value={el} index={i} key={i}></SortableItem>
                  );
                })}
              </ul>
            );
          });
          return <SectionOrderContainer onSortEnd={onSortEnd} axis="y" />;
        })()}
        <small>Drag an element to change its order</small>
      </div>
    </div>
  );
}

export default PortfolioSettingsForm;
