import React from 'react'


const { createContext, useReducer, useContext } = React;


export type LoadingState = {
    loading: boolean
}

export type LoadingStateDispatchType = (values: LoadingState) => void;

const initialLoadingState: LoadingState = { loading: false }

const LoadingStateContext = createContext(initialLoadingState)
const DispatchLoadingStateContext = createContext({} as LoadingStateDispatchType)


export const LoadingStateProvider = ({ children }: { children: any }) => {
    const [state, dispatch] = useReducer(
        (state: LoadingState, newValue: LoadingState) => {
            return newValue
        },
        initialLoadingState
    )
    return (
        <LoadingStateContext.Provider value={state}>
            <DispatchLoadingStateContext.Provider value={dispatch}>
                {children}
            </DispatchLoadingStateContext.Provider>
        </LoadingStateContext.Provider>
    )
}

export const useLoadingState = (): [LoadingState, LoadingStateDispatchType] => {
    return [
        useContext(LoadingStateContext),
        useContext(DispatchLoadingStateContext)
    ]
}