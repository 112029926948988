import * as React from 'react'
import { history } from '../../utils/history'

import styles from './Error.module.css'
type PropType = {
    error?: string,
}

const defaultProps: PropType = {
    error: `Unknown error. Please try again or report a bug.`
}

const ErrorComponent: React.FC<PropType> = (props: PropType) => {
    return (

        <div style={{ textAlign: 'center', margin: 'auto' }}>
            <h1 style={{ paddingTop: 30, marginBottom: 30, fontSize: '3rem' }}>Something went wrong...</h1>
            <div className={`alert alert--danger ${styles.errorBox}`} role="alert">
                {props.error}
            </div>
            <button onClick={() => history.goBack()} className={`button button--secondary button--outline ${styles.btn}`}>
                Go Back
            </button>
            <a href='/'>
                <button className={`button button--secondary button--outline ${styles.btn}`}>
                    Go Home
            </button>
            </a>
            <a href='mailto:claperone.portosaurus@gmail.com'>

                <button className="button button--secondary button--outline">
                    Report a Bug
            </button>
            </a>
        </div>
    )
}

ErrorComponent.defaultProps = defaultProps

export default ErrorComponent

