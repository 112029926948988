import React from "react"
import { SortableHandle } from "react-sortable-hoc";


//doens't need hand icon when hover because it's only being used in mobile
//note: do not use div, or the whole width becomes draggable. also need to wrap in button

const DragHandle = () => {
  const SortableDragHandle = SortableHandle(() => {
    return <button style={{
      touchAction: "none",
      background: "transparent",
      border: "none",
      outline: "none"

    }}>
      <h1 >:::</h1>
    </button>
  }
  );

  return <SortableDragHandle />
}

export default DragHandle