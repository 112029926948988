import React, { useState } from 'react'
import { ProfileComponentProps, defaultProfileComponentProps, ItemModProp, addItemToArraySt, deleteItemFromArraySt } from '../common'
import styles from '../common.module.css'
import { MainEditButton, AddNewButton, DiscardChangesButton, SaveChangesButton } from '../Subcomponents/EditButtons'
import { message } from 'antd'
import { useProfileState } from '../../../../contexts/profileState'
import { updateWrapper, updateContacts } from '../../../../utils/firebase/profile'
import { useLoadingState } from '../../../../contexts/loadingState'
import { history } from '../../../../utils/history'
import { endpoints } from '../../../../consts'
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { capitalisePhrase } from '../../../../utils/utils'
import { AddEditModal } from './Form'

export const itemName = `contact`
export const pluralItemName = `contacts`
const id = itemName

const ContactTag = (props: { contact: Contact, parentProps: ProfileComponentProps, onDelete: () => Promise<void> }) => {

  const { contact, parentProps, onDelete } = props
  const { mode } = parentProps
  const { name, url } = contact
  return (
    <div className={`${styles.contactTag} alert alert--secondary`}>
      {mode === `edit` &&
        <span onClick={onDelete} aria-label="Close" className="close">
          <span aria-hidden="true">×</span>
        </span>
      }
      {name}: <a href={url} target="_blank" rel="noopener noreferrer">{url}</a>
    </div>
  )
}

export const Contacts = (props: ProfileComponentProps) => {
  const { mode } = props
  const [state,] = useProfileState()
  const [, dispatchLoadingState] = useLoadingState()
  const { profile } = state
  const [contacts, setContacts] = useState<Contact[]>(profile?.contacts ?? [])
  const [showModal, setShowModal] = useState(false)

  const initialItemMod: ItemModProp<Contact> = {
    item: {
      name: ``,
      url: ``,
    },
    onOk: async (item, index) => {
      addItemToArraySt(contacts, setContacts)(item, index)
      setShowModal(false)
    },
    onCancel: async () => { setShowModal(false) },
    mode: `add`,
    index: undefined
  }

  const onSortEnd = (props: { oldIndex: number, newIndex: number }) => {
    setContacts(arrayMove(contacts, props.oldIndex, props.newIndex))
  };

  return (<div className={styles.root} id={id}>
    <div className={`card ${styles.cardBody}`}>
      <div className="card__header margin--auto">
        <h3 style={{ fontSize: 36, marginTop: 0, marginBottom: 0 }}>{capitalisePhrase(pluralItemName)}</h3>
      </div>
      <div className={`card__body ${styles.innerCardBody}`}>
        <hr />
        {
          contacts.length ?
            <div style={{ maxWidth: 600, margin: `auto` }}>
              {
                mode === `edit`
                  ?
                  (function () {
                    const SortableContactContainer =
                      SortableContainer(() => {
                        return <ul style={{ padding: 0 }}>
                          {contacts.map((c, i) => {
                            const SortableContact = SortableElement(() => {
                              return <ContactTag
                                contact={c}
                                key={i}
                                parentProps={props}
                                onDelete={
                                  deleteItemFromArraySt(i, contacts, setContacts)
                                } />
                            })
                            return <SortableContact key={`${i}-sortable`} index={i} ></SortableContact>

                          })}
                        </ul>

                      })
                    return <SortableContactContainer distance={2} axis="y" onSortEnd={onSortEnd}></SortableContactContainer>

                  }()
                  )
                  :
                  contacts.map((c, i) => {
                    return <ContactTag
                      contact={c}
                      key={i}
                      parentProps={props}
                      onDelete={
                        deleteItemFromArraySt(i, contacts, setContacts)
                      } />
                  })
              }
              {contacts.length > 1 && mode === `edit` && <p>Drag the {pluralItemName} to change the order</p>}
            </div>
            :
            <div>
              <p>Add some {pluralItemName} and they will appear here!</p>
            </div>
        }
      </div>



      {
        mode === `view` &&
        <MainEditButton pluralItemName={pluralItemName} targetUrl={`/${endpoints.profile}/edit/contacts`} />
      }
      {
        mode === `edit` &&
        <div>
          <AddNewButton itemName={itemName} action={async () => {
            if (contacts.length < 10) {
              setShowModal(true)
            }
            else {
              message.error(`You can only add up to 10 contacts`)
            }
          }} />
          <div>
            <DiscardChangesButton />
            <SaveChangesButton
              action={async () => {
                try {
                  const updateFunc = async () => await updateContacts(contacts, profile?.contacts ?? [])
                  await updateWrapper(dispatchLoadingState, updateFunc)
                  history.push(`/${endpoints.profile}`)
                }
                catch (err) {
                  console.error(err)
                  message.error(`Something went wrong...`)
                }
              }} />
          </div>
        </div>
      }
    </div>
    <AddEditModal itemModProp={initialItemMod} show={showModal} />
  </div >)
}

Contacts.defaultProps = defaultProfileComponentProps
