/**
 * This does not delete or write to firebase storage
 * It merely sets the file in the original component
 * File deletion and update handling should be done after 
 * it is set in the parent component 
 */

import React, { useState } from 'react'
import imageCompression from 'browser-image-compression';
import { Upload, message, Button } from 'antd';
import { LoadingOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { DeleteButton } from './EditButtons'
import styles from './ImageUpload.module.css'

type Prop = {
  originalDownloadUrl?: string
  setFile: (file: File | undefined) => Promise<void> // undefined means deleted
  size: `sm` | `md` | `lg`
  setSubmitDisabled: (disabled: boolean) => Promise<void>
}

export const ImageUpload = (props: Prop) => {
  const { originalDownloadUrl, setFile, size, setSubmitDisabled } = props
  const [loading, setLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState<string | undefined>(originalDownloadUrl)

  const beforeUpload = (file: File) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('Unsupported file type');
    }
    return isJpgOrPng
  }


  const toBase64 = (file: File) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  const handleDelete = async () => {
    setImageUrl(undefined)
    await setFile(undefined)
  
  }

  const getMaxWidthOrHeight = () => {
    switch (size) {
      case `lg`: 
        return 3840
      case `md`:
        return 1024
      case `sm`:
        return 512
    }
  }

  const getMaxSizeMB = () => {
    switch (size) {
      case `lg`:
        return 5
      case `md`:
        return 2
      case `sm`:
        return 1
    }
  }

  const handleChange = async ({ file }: { file: File }) => {
    setLoading(true)
    setSubmitDisabled(true)

    const maxWidthOrHeight = getMaxWidthOrHeight()
    const maxSizeMB = getMaxSizeMB()

    try {
      const compressedImg = await imageCompression(file, { maxWidthOrHeight: maxWidthOrHeight, maxSizeMB: maxSizeMB })
      const compressedFile = compressedImg as File
      await setFile(compressedFile)
      setImageUrl((await toBase64(compressedFile)) as string)
    }
    catch (err) {
      message.error(`Something went wrong`)
      // console.error(err)
    }
    setLoading(false)
    setSubmitDisabled(false)
  }

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  )

  return (
    <div>
      {size === `sm` && <div>
        <Upload
          name="image"
          accept="image/png,image/jpeg"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          beforeUpload={beforeUpload}
          customRequest={
            handleChange
          }
        >
          {imageUrl && !loading ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
        </Upload>
        {imageUrl && !loading && <div className={`${styles.deleteImageWrapper}`}>
          <DeleteButton onDelete={handleDelete} itemName="image" />
        </div>}
      </div>}
      {(size === `lg` || size === `md`) && <div>

        <Upload
          name="image"
          accept="image/png,image/jpeg"
          // listType="picture-card"
          // className="avatar-uploader"
          showUploadList={false}
          beforeUpload={beforeUpload}
          customRequest={
            handleChange
          }
        >

          <Button loading={loading} disabled={loading}>
            {loading ?
              <span>Uploading...</span>
              :
              <span>
                <UploadOutlined /> Upload Image
            </span>
            }
          </Button>
        </Upload>
        {imageUrl && !loading &&
          <div style={{ margin: `auto` }}>
            <img src={imageUrl} alt="" style={{ width: `100%`, marginTop: 12, marginBottom: 12 }} />
            <div className={`${styles.deleteImageWrapper}`}>
              <DeleteButton onDelete={handleDelete} itemName="image" />
            </div>
          </div>}
      </div>}
    </div>
  );
}