export const getQueryVariable = (variable: string): string | undefined => {
    const query = window.location.search.substring(1)
    const pairs = query.split(`&`);
    for (const pair of pairs) {
        const [key, val] = pair.split(`=`);
        if (key === variable) {
            return val;
        }
    }
    return undefined;
}


const notUndefined = <T>(x: T | undefined): x is T => {
    return x !== undefined;
}

export const joinStringsWithDot = (strs: (string | undefined)[]) => {
    return strs
        .filter(notUndefined)
        .filter(s => s.length > 0)
        .join(` · `)
}

export async function delay(milliseconds?: number) {
    await new Promise((resolve) => setTimeout(resolve, milliseconds))

}

export function replaceNewlinesWithBr(str: string): string {
    return str.toString().replace(/(\r\n|\n|\r)/gm, "<br/>");
}

const capitaliseFirstChar = (s: string) => {
    return s.charAt(0).toUpperCase() + s.slice(1)
}

export const capitalisePhrase = (str: string) => {
    return str
        .split(` `)
        .map(s => capitaliseFirstChar(s))
        .join(` `)
    
}