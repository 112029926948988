import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.css';


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

/// to disable scrolling for <input type="number" />
document.addEventListener("wheel", function (event) {
  const activeElem = document.activeElement
  if (activeElem) {
    /// this is very hacky
    const activeHTMLInputElement = activeElem as HTMLInputElement
    if (activeHTMLInputElement.type === "number") {
      activeHTMLInputElement.blur()
    }
  }
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
