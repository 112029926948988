import React, { useState } from 'react'
import { ItemModProp, NextImageType, getNextImageDownloadUrlAndCleanup, NextImageDownloadUrlAndCleanup, getImageCleanUp } from '../common'
import { DiscardChangesButton, SaveChangesButton, DeleteButton } from '../Subcomponents/EditButtons'
import { intMonthToFullMonthMap, monthYearLessThan, yupYearValidator } from '../../../../utils/datetime'
import { removeUndefined } from '../../../../utils/firebase/profile'
import { Modal, message } from 'antd'
import { Formik } from 'formik'
import * as yup from 'yup'
import { Form, Col } from 'react-bootstrap'
import { ImageUpload } from '../Subcomponents/ImageUpload'
import { currentYear } from '../../../../consts'
import { id, itemName } from './JobPositions'

export const AddEditDeleteModal = (props: { itemModProp: ItemModProp<JobPosition>; show: boolean; uid: string }) => {

  const [disabled, setDisabled] = useState(false)
  const { show, itemModProp, uid } = props
  const { item, onOk, onCancel, mode, index, onDelete } = itemModProp
  const addOrEdit = mode === `add` ? `Add` : `Edit`
  const [nextCompanyLogo, setNextCompanyLogo] = useState<NextImageType>({
    file: undefined,
    update: false
  })

  type FormType = {
    title: string
    companyName: string
    companyLogo?: DownloadUrl
    url?: string
    dateRange: DateRange
    location?: string
    description?: string
  }

  const toPresent = item.dateRange.toPresent

  const initialValues: FormType = {
    title: item.title,
    companyName: item.companyName,
    companyLogo: item.companyLogo,
    url: item.url,
    dateRange: {
      monthFrom: item.dateRange.monthFrom,
      yearFrom: item.dateRange.yearFrom,
      toPresent: toPresent,
      monthTo: toPresent ? 0 : item.dateRange.monthTo,
      yearTo: toPresent ? currentYear : item.dateRange.yearTo
    },
    location: item.location,
    description: item.description ?? ""
  }

  const toActualTypeAndCleanups = async (vals: FormType): Promise<{ jobPosition: JobPosition, cleanups: (() => Promise<void>)[] }> => {
    let cleanups: (() => Promise<void>)[] = []

    const { title, companyName, url, location, description, dateRange } = vals

    const nextCompanyLogoDownloadUrlAndCleanup: NextImageDownloadUrlAndCleanup = await getNextImageDownloadUrlAndCleanup(id, vals.companyLogo, nextCompanyLogo, uid)
    const companyLogo = nextCompanyLogoDownloadUrlAndCleanup.downloadUrl
    if (nextCompanyLogoDownloadUrlAndCleanup.cleanup) {
      cleanups.push(nextCompanyLogoDownloadUrlAndCleanup.cleanup)
    }

    const _jobPosition: JobPosition = {
      title,
      url,
      companyName,
      companyLogo,
      location,
      dateRange,
      description
    }

    const jobPosition = (await removeUndefined(_jobPosition)) as JobPosition
    return {
      jobPosition,
      cleanups
    }
  }

  const schema = yup.object({
    companyName: yup.string()
      .max(128, `Must be 128 characters or fewer`)
      .required(`Required`),
    title: yup.string()
      .max(128, `Must be 128 characters or fewer`)
      .required(`Required`),
    companyLogo: yup.mixed(),
    // .test(`file-size`, `File size must be less than 1MB`, (value: File) => value.size <= 1024 * 1024)
    // .test(`file-type`, `Unsupported File format`, (value: File) => value.type.substring(0, 5) === `image`),
    url: yup.string()
      .max(128, `Must be 128 characters or fewer`)
      .url(`Please enter a valid URL, e.g. https://www.example.com`),
    dateRange: yup.object({
      monthFrom: yup.string()
        .oneOf(Object.keys(intMonthToFullMonthMap))
        .required(`Required`),
      yearFrom: yupYearValidator
        .required('Required'),
      toPresent: yup.boolean(),
      monthTo: yup.string()
        .oneOf(Object.keys(intMonthToFullMonthMap))
        .when(`toPresent`, {
          is: false,
          then: (fieldSchema: yup.StringSchema<string>) =>
            fieldSchema
              .required(`Required`)
              .test(`date-test`, `End date cannot be before start date`, function () {
                const { monthFrom, yearFrom, monthTo, yearTo } = this.parent
                return (monthFrom === monthTo && yearFrom === yearTo)
                  || monthYearLessThan(
                    { sMonth: monthFrom, year: yearFrom },
                    { sMonth: monthTo, year: yearTo })
              })
        }),
      yearTo: yupYearValidator
        .when(`toPresent`, {
          is: false,
          then: (fieldSchema: yup.StringSchema<string>) => fieldSchema.required(`Required`)
        })
    }),
    location: yup.string()
      .max(128, `Must be 128 characters or fewer`),
    description: yup.string()
      .max(4096, `Must be 4096 characters or fewer`),
  })

  return (
    <Modal
      title={`${addOrEdit} ${itemName}`}
      visible={show}
      centered
      footer={null}
      onCancel={onCancel}
      maskClosable={false}
      closable={false}
      destroyOnClose
      keyboard={false}
      zIndex={1021}
    >
      <Formik
        validationSchema={schema}
        onSubmit={async (vals, { resetForm }) => {
          setDisabled(true)
          try {
            const { jobPosition, cleanups } = await toActualTypeAndCleanups(vals)
            onOk(jobPosition, index, cleanups)
          }
          catch (err) {
            console.error(err)
            message.error(`Something went wrong`)
          }
          setDisabled(false)
          resetForm()
        }}
        enableReinitialize
        initialValues={initialValues}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          isValid,
          errors,
        }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group controlId="jobPosition">

                <Form.Label>Title*</Form.Label>
                <Form.Control
                  type="text"
                  name="title"
                  value={values.title}
                  placeholder="Enter title"
                  onChange={handleChange}
                  isInvalid={!!errors.title}
                  isValid={touched.title && !errors.title}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.title}
                </Form.Control.Feedback>

                <Form.Label>Company Name*</Form.Label>
                <Form.Control
                  type="text"
                  name="companyName"
                  value={values.companyName}
                  placeholder="Enter company name"
                  onChange={handleChange}
                  isInvalid={!!errors.companyName}
                  isValid={touched.companyName && !errors.companyName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.companyName}
                </Form.Control.Feedback>


                <Form.Label>Company Logo</Form.Label>
                <ImageUpload
                  originalDownloadUrl={values.companyLogo}
                  setFile={async (file: File | undefined) => {
                    setNextCompanyLogo({
                      file,
                      update: true
                    })
                  }}
                  size="sm"
                  setSubmitDisabled={async (disabled: boolean) => setDisabled(disabled)}
                />

                <Form.Label>Company URL</Form.Label>
                <Form.Control
                  type="text"
                  name="url"
                  value={values.url}
                  placeholder="Enter company URL"
                  onChange={handleChange}
                  isInvalid={!!errors.url}
                  isValid={touched.url && !errors.url}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.url}
                </Form.Control.Feedback>

                <Form.Label>Start Date*</Form.Label>
                <Form.Row>
                  <Col>
                    <Form.Control
                      as="select"
                      name="dateRange.monthFrom"
                      value={values.dateRange.monthFrom}
                      // placeholder="Enter title"
                      onChange={handleChange}
                      isInvalid={!!errors.dateRange?.monthFrom}
                      isValid={touched.dateRange?.monthFrom && !errors.dateRange?.monthFrom}
                    >
                      {
                        Object.entries(intMonthToFullMonthMap).map(([monthInt, monthFull]) => <option key={monthInt} value={monthInt}>{monthFull}</option>)
                      }
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.dateRange?.monthFrom}
                    </Form.Control.Feedback>

                  </Col>
                  <Col>
                    <Form.Control
                      type="number"
                      name="dateRange.yearFrom"
                      value={values.dateRange.yearFrom}
                      placeholder="Year"
                      onChange={handleChange}
                      isInvalid={!!errors.dateRange?.yearFrom}
                      isValid={touched.dateRange?.yearFrom && !errors.dateRange?.yearFrom}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.dateRange?.yearFrom}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Row>


                <Form.Label>End Date*</Form.Label>
                <Form.Check
                  name="dateRange.toPresent"
                  checked={values.dateRange.toPresent}
                  label={`Present`}
                  onChange={handleChange}
                />
                {
                  !values.dateRange.toPresent &&
                  <Form.Row>
                    <Col>
                      <Form.Control
                        type="number"
                        as="select"
                        name="dateRange.monthTo"
                        value={values.dateRange.monthTo}
                        // placeholder="Enter title"
                        onChange={handleChange}
                        isInvalid={!!errors.dateRange?.monthTo}
                        isValid={touched.dateRange?.monthTo && !errors.dateRange?.monthTo}
                      >
                        {
                          Object.entries(intMonthToFullMonthMap).map(([monthInt, monthFull]) => <option key={monthInt} value={monthInt}>{monthFull}</option>)
                        }
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.dateRange?.monthTo}
                      </Form.Control.Feedback>

                    </Col>
                    <Col>
                      <Form.Control
                        type="number"
                        name="dateRange.yearTo"
                        value={values.dateRange.yearTo}
                        placeholder="Year"
                        onChange={handleChange}
                        isInvalid={!!errors.dateRange?.yearTo}
                        isValid={touched.dateRange?.yearTo && !errors.dateRange?.yearTo}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.dateRange?.yearTo}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Row>
                }

                <Form.Label>Location</Form.Label>
                <Form.Control
                  type="text"
                  name="location"
                  value={values.location}
                  placeholder="Enter location"
                  onChange={handleChange}
                  isInvalid={!!errors.location}
                  isValid={touched.location && !errors.location}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.location}
                </Form.Control.Feedback>


                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  name="description"
                  value={values.description}
                  placeholder="Enter description"
                  onChange={handleChange}
                  isInvalid={!!errors.description}
                  isValid={touched.description && !errors.description}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.description}
                </Form.Control.Feedback>
              </Form.Group>
              {
                mode === `edit` &&
                <div style={{ textAlign: `center` }}>
                  <DeleteButton onDelete={async () => {
                    /// we delete the images after it's set
                    if (onDelete) {
                      let cleanups: (() => Promise<void>)[] = []
                      if (item.companyLogo) {
                        cleanups.push(await getImageCleanUp(item.companyLogo))
                      }
                      await onDelete(cleanups)
                    }
                  }
                  }
                    itemName={itemName} />
                </div>
              }
              <div style={{ textAlign: `center` }}>
                <DiscardChangesButton onDiscard={onCancel} />
                <SaveChangesButton type="submit" disabled={!touched || !isValid || disabled} />
              </div>
            </Form>
          )}
      </Formik>
    </Modal >
  )
}