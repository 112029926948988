import React from 'react'
import { ItemModProp } from '../common'
import { DiscardChangesButton, SaveChangesButton } from '../Subcomponents/EditButtons'
import * as yup from 'yup'
import { Formik } from 'formik'
import { Modal } from 'antd'
import { Form } from 'react-bootstrap'
import { itemName } from './Contacts'

export const AddEditModal = (props: { itemModProp: ItemModProp<Contact>; show: boolean }) => {
    const { show, itemModProp } = props
    const { item, onOk, onCancel, mode, index } = itemModProp
    const addOrEdit = mode === `add` ? `Add` : `Edit`

    const schema = yup.object({
        name: yup.string()
            .max(32, `Must be 32 characters or fewer`)
            .required(`Required`),
        url: yup.string()
            .max(256, `Must be 256 characters or fewer`)
            .required(`Required`)
    })


    return (
        <Modal
            title={`${addOrEdit} ${itemName}`}
            visible={show}
            // handleClose={onCancel}
            centered
            footer={null}
            onCancel={onCancel}
            maskClosable={false}
            keyboard={false}
            closable={false}
            zIndex={1021}
            destroyOnClose
        >
            <Formik
                enableReinitialize
                validationSchema={schema}
                onSubmit={(vals, { resetForm }) => {
                    onOk(vals, index, [])
                    resetForm()
                }}
                initialValues={{
                    name: item.name,
                    url: item.url
                }}
            >
                {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    isValid,
                    errors,
                    resetForm
                }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group controlId="contact">
                                <Form.Label>Contact Type*</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    value={values.name}
                                    placeholder="Enter contact type"
                                    onChange={handleChange}
                                    isInvalid={!!errors.name}
                                    isValid={touched.name && !errors.name}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.name}
                                </Form.Control.Feedback>
                                <Form.Label>URL*</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="url"
                                    value={values.url}
                                    placeholder="Enter URL"
                                    onChange={handleChange}
                                    isInvalid={!!errors.url}
                                    isValid={touched.url && !errors.url}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.url}
                                </Form.Control.Feedback>
                                <p style={{ marginTop: 12 }}>Entering an email? Make sure to prepend your email address with <code>mailto:</code>, e.g. <code>mailto:example@example.com</code>.</p>
                                <p>Links to other websites should start with <code>http://</code> or <code>https://</code>.</p>
                            </Form.Group>
                            <div style={{ textAlign: `center` }}>
                                <DiscardChangesButton onDiscard={async () => { resetForm(); onCancel() }} />
                                <SaveChangesButton type="submit" disabled={!touched || !isValid} />
                            </div>
                        </Form>
                    )}
            </Formik>
        </Modal>
    )
}
