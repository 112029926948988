import React from 'react';
import 'antd/dist/antd.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/infima/dist/css/default-dark/default-dark.css';
import './App.css';
import Layout from './containers/Layout/Layout';

import { message } from 'antd'

import { Router } from 'react-router-dom';
import { history } from './utils/history';
import { IS_PRODUCTION } from './consts';
import './utils/firebase/firebase'; //to initialise the firebase app
import { ProfileStateProvider } from './contexts/profileState';
import { LoadingStateProvider } from './contexts/loadingState';


message.config({
  top: 64
})

if (!IS_PRODUCTION) {
  console.warn(`DEV MODE`)
}

function App() {
  return (
    <LoadingStateProvider>
      <ProfileStateProvider>
        <Router history={history}>
          <Layout />
        </Router>
      </ProfileStateProvider>
    </LoadingStateProvider>
  )
}

export default App
