import React from 'react'

import styles from './ChooseTheme.module.css'

import { chunk } from 'lodash'
 
import infimaDarkImg from '../../assets/portfolios/infima_dark.png'
import infimaLightImg from '../../assets/portfolios/infima_light.png'
 

const portfolioThemes: PortfolioThemes = {
  infima_dark: {
    name: `Infima Dark`,
    portfolioThemeName: `infima_dark`,
    imgUrl: infimaDarkImg,
    url: `/portfolios/infima_dark/index.html`,
    author: `Portosaurus`,
    authorUrl: `https://www.portosaurus.com`,
  },
  infima_light: {
    name: `Infima Light`,
    portfolioThemeName: `infima_light`,
    imgUrl: infimaLightImg,
    url: `/portfolios/infima_light/index.html`,
    author: `Portosaurus`,
    authorUrl: `https://www.portosaurus.com`
  }
}



const ThemeCard = (props: {
  portfolioTheme: PortfolioTheme,
  offset: number
  handleChooseClick: () => void
}) => {
  const { portfolioTheme, offset } = props
  const { name, imgUrl, author, authorUrl } = portfolioTheme
  return (
    <div className={`col col--6 ${offset && `col--offset-${offset}`}`} style={{ marginBottom: `1.5rem` }}>
      <div className="card" style={{ backgroundColor: `var(--ifm-color-gray-800)`, height: `100%` }}>
        <div className="card__header">
          <div className="avatar">
            <div className="avatar__intro">
              <h4 className="avatar__name" style={{ fontSize: `1.5rem` }}>{name}</h4>
              {
                author &&
                <small className="avatar__subtitle">
                  Author: <a href={authorUrl} rel="noopener noreferrer" target="_blank">{author}</a>
                </small>
              }
            </div>
          </div>
        </div>
        <div className="card__image">
          <img
            src={imgUrl}
            alt={name}
            title={name}
          />
        </div>
        <div className="card__footer">
          <div className="button-group button-group--block">
            <button onClick={() => props.handleChooseClick()} className="button button--secondary" style={{ width: `100%` }}>Choose</button>


          </div>
        </div>
      </div>
    </div>
  )
}

const ChooseTheme = (props: {
  handleChooseClick: (portfolioTheme: PortfolioTheme) => void
}) => {
  const getThemes = (themes: PortfolioTheme[]): React.ReactElement[] => {
    let ret: React.ReactElement[] = []

    const groupedThemes = chunk(themes, 2)

    for (let i = 0; i < groupedThemes.length; ++i) {
      ret.push(
        <div className="row" key={i}>
          {groupedThemes[i].map((t, index) => {
            const trueIndex = i * 2 + index
            return <ThemeCard handleChooseClick={() => props.handleChooseClick(t)} portfolioTheme={t} offset={groupedThemes[i].length === 1 ? 3 : 0} key={trueIndex} />
          })
          }
        </div>
      )
    }
    return ret
  }

  return (
    <div className={styles.root}>

      {/* <div className={`card ${styles.cardBody}`}> */}
      {/* 
      <CloseOutlined style={{right: 20, position: 'absolute'}} onClick={() => props.handleCloseClick()}/> */}

      {/* <div className="card__header margin--auto"> */}
      <h3 style={{ fontSize: 36, marginTop: 0, marginBottom: 0 }}>Choose Theme</h3>
      {/* </div> */}
      {/* <div className={`card__body ${styles.innerCardBody}`}> */}
      <hr />
      {getThemes(Object.values(portfolioThemes))}

      <div style={{ marginTop: 32 }}>
        <h3 style={{ fontSize: `2rem`, marginBottom: 0 }}>More themes coming soon!</h3>
        <h4 style={{ margin: 0 }}>Want to contribute?{` `}
          <a className="footer__link-item" target="_blank" rel="noopener noreferrer" href="mailto:claperone.portosaurus@gmail.com">
            Contact us.
                    </a></h4>
        {/* </div> */}
        {/* </div> */}
      </div>
    </div >)
}

export default ChooseTheme