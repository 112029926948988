import React, { useState } from 'react'
import { ProfileComponentProps, defaultProfileComponentProps, ItemModProp, addItemToArraySt, deleteItemFromArraySt } from '../common'
import styles from '../common.module.css'
import { MainEditButton, DiscardChangesButton, SaveChangesButton } from '../Subcomponents/EditButtons'
import { message } from 'antd'
import { useProfileState } from '../../../../contexts/profileState'
import { updateSkills, updateWrapper } from '../../../../utils/firebase/profile'
import { useLoadingState } from '../../../../contexts/loadingState'
import { history } from '../../../../utils/history'
import { endpoints } from '../../../../consts'
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { capitalisePhrase } from '../../../../utils/utils'
import { AddNewSkillTextBox } from './Form'

export const itemName = `skill`
export const pluralItemName = `skills`
const id = itemName


const SkillTag = (props: { skill: Skill, parentProps: ProfileComponentProps, onDelete: () => Promise<void> }) => {
    const { skill, parentProps, onDelete } = props
    const { mode } = parentProps
    const { name, url } = skill

    const skillContent = (url && url.length > 0) ?
        <a className={styles.noGreenLink} href={url} target="_blank" rel="noopener noreferrer">{name}</a> : <span>{name}</span>
    return (
        <span className={`badge badge--secondary ${styles.skill}`} style={{ margin: 6, fontSize: `1.2rem`, paddingRight: mode === `edit` ? 4 : 8, backgroundColor: `transparent`, color: `white` }}>
            {skillContent}
            {mode === `edit` &&
                <button style={{ border: "none", outline: "none", paddingRight: 0, paddingTop: 0, backgroundColor: `transparent` }} onClick={onDelete}>×</button>
            }
        </span>
    )
}


export const Skills = (props: ProfileComponentProps) => {

    const { mode } = props
    const [state,] = useProfileState()
    const [, dispatchLoadingState] = useLoadingState()
    const { profile } = state
    const [skills, setSkills] = useState<Skill[]>(profile?.skills ?? [])
    // const [showModal, setShowModal] = useState(false)

    const initialItemMod: ItemModProp<Skill> = {
        item: { name: `` },
        onOk: async (item, index) => {
            addItemToArraySt(skills, setSkills)(item, index)
            // setShowModal(false)
        },
        onCancel: async () => { },
        mode: `add`,
        index: undefined
    }

    const onSortEnd = (props: { oldIndex: number, newIndex: number }) => {
        setSkills(arrayMove(skills, props.oldIndex, props.newIndex))
    };



    return (<div className={styles.root} id={id}>
        <div className={`card ${styles.cardBody}`}>
            <div className="card__header margin--auto">
                <h3 style={{ fontSize: 36, marginTop: 0, marginBottom: 0 }}>{capitalisePhrase(pluralItemName)}</h3>
            </div>
            <div className={`card__body ${styles.innerCardBody}`}>
                <hr />
                {
                    skills.length ?
                        <div>
                            {
                                mode === `edit`
                                    ?
                                    (function () {
                                        const SortableSkillContainer =
                                            SortableContainer(() => {
                                                return <ul style={{ padding: 0 }}>
                                                    {skills.map((s, i) => {
                                                        const SortableSkill = SortableElement(() => {
                                                            return <SkillTag skill={s} key={i} parentProps={props}
                                                                onDelete={deleteItemFromArraySt(i, skills, setSkills)} />
                                                        })
                                                        return <SortableSkill key={`${i}-sortable`} index={i} ></SortableSkill>

                                                    })}
                                                </ul>

                                            })
                                        return <SortableSkillContainer distance={2} axis="xy" onSortEnd={onSortEnd}></SortableSkillContainer>

                                    }()
                                    )
                                    :
                                    skills.map((s, i) => {
                                        return <SkillTag skill={s} key={i} parentProps={props}
                                            onDelete={deleteItemFromArraySt(i, skills, setSkills)} />
                                    })
                            }
                            {skills.length > 1 && mode === `edit` && <p>Drag the {pluralItemName} to change the order</p>}
                        </div>
                        :
                        <div>
                            <p>Add some {pluralItemName} and they will appear here!</p>
                        </div>
                }
            </div>



            {
                mode === `view` &&
                <MainEditButton pluralItemName={pluralItemName} targetUrl={`/${endpoints.profile}/edit/skills`} />
            }
            {
                mode === `edit` &&
                <div>
                    <div style={{ marginTop: 32 }} className={styles.addSkillBox} >
                        <AddNewSkillTextBox itemModProp={initialItemMod} allSkills={skills} />
                    </div>
                    <div>
                        <DiscardChangesButton />
                        <SaveChangesButton action={async () => {
                            try {
                                const updateFunc = async () => await updateSkills(skills, profile?.skills ?? [])
                                await updateWrapper(dispatchLoadingState, updateFunc)
                            }
                            catch (err) {
                                console.error(err)
                                message.error(`Something went wrong...`)
                            }
                            history.push(`/${endpoints.profile}`)
                        }} />
                    </div>
                </div>
            }
        </div>
        {/* <AddEditModal itemModProp={itemMod} show={showModal} allSkills={skills} /> */}
    </div >)
}

Skills.defaultProps = defaultProfileComponentProps
