import * as React from 'react'
import { Link } from 'react-router-dom'
import { currentYear, endpoints } from '../../../consts'

const Footer = () => {
    return (
        <footer className="footer" style={{ color: 'white', backgroundColor: `var(--ifm-color-gray-900)` }}>
            <div className="container container--fluid text--center">
                <div className="footer__links">Copyright © {currentYear} Portosaurus </div>

                <div className="footer__links">
                    <a className="footer__link-item" target="_blank" rel="noopener noreferrer" href={`mailto:claperone.portosaurus@gmail.com`}>Contact</a>
                    <span className="footer__link-separator">·</span>
                    <Link className="footer__link-item" to={`/${endpoints.privacyPolicy}`}>Privacy Policy</Link>
                    <span className="footer__link-separator">·</span>
                    <Link className="footer__link-item" to={`/${endpoints.termsOfService}`}>Terms of Service</Link>
                    <span className="footer__link-separator">·</span>
                    <Link className="footer__link-item" to={`/${endpoints.faq}`}>FAQ</Link>
                </div>
            </div>
        </footer>
    )
}

export default Footer