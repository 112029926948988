import * as React from 'react'
import Header from './Header/Header'
import AppContent from './AppContent/AppContent'
import Footer from './Footer/Footer'
import { useLoadingState } from '../../contexts/loadingState'
import Loading from '../../components/Loading/Loading'

const Layout = () => {
    const [loadingState,] = useLoadingState()

    return (
        <div>
            <Header />
            <div style={{ minHeight: '84vh', backgroundColor: 'black', color: 'white' }}>
                {
                    loadingState.loading ?
                        <Loading />
                        : <AppContent />

                }
            </div>
            <Footer />
        </div>
    )
}

export default Layout