import React, { useState } from 'react'
import { ProfileComponentProps, defaultProfileComponentProps, addItemToArray, ItemModProp, deleteItemFromArray } from '../common'
import styles from '../common.module.css'
import { joinStringsWithDot, replaceNewlinesWithBr } from '../../../../utils/utils';
import defaultInsititution from '../../../../assets/defaultInstitution.png'
import { MainEditButton, AddNewButton, IndivEditButtons, DoneButton } from '../Subcomponents/EditButtons'
import { useProfileState } from '../../../../contexts/profileState'
import { useLoadingState } from '../../../../contexts/loadingState'
import { dateRangeDisplay } from '../../../../utils/datetime'
import { updateJobPositions, updateWrapper } from '../../../../utils/firebase/profile'
import { message } from 'antd'
import { currentYear, endpoints } from '../../../../consts'
import { history } from '../../../../utils/history'

import { AddEditDeleteModal } from './Form'
import { SortableElement, SortableContainer } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import DragHandle from '../../../../components/DragHandle/DragHandle';
import useIsMobileDevice from '../../../../utils/useIsMobileDevice';
import InnerHTML from 'dangerously-set-html-content'

export const itemName = `experience`
export const pluralItemName = `experience`
export const id = `experience`

// const testposs: JobPosition[] = [
//   {
//     title: "Spring Intern",
//     companyName: "J.P. Morgan",
//     companyLogo: `https://media-exp1.licdn.com/dms/image/C4E0BAQGvyWbgDy4E3A/company-logo_200_200/0?e=1599696000&v=beta&t=QMyuJt6Vy_1GTzvL0LyPJ_iueKRqcMeRFlfl1VNAFtA`,
//     url: `https://www.linkedin.com/company/jpmorgan/`,
//     monthYearFrom: `Jan 2019`,
//     monthYearTo: `Jun 2019`,
//     location: `London`,
//     description: [`I built the alpha of a development environment in PHP that allows the business to track desktop assets and software licensing. From this, I obtained a good grasp of how clients and servers interact with each other.`,
//       `I provided design feedback and testing for a new video game in development. I wrote notes and feedback and then shared this with other team members in design meetings, giving them valuable insight that was largely taken on board.`
//     ]
//   },
//   {
//     title: "Spring Intern",
//     companyName: "J.P. Morgan",
//     monthYearFrom: `Jan 2019`,
//     monthYearTo: `Jun 2019`,
//   }
// ]


export const JobPositions = (props: ProfileComponentProps) => {
  const { mode } = props
  const [state,] = useProfileState()
  const { profile } = state
  const [jobPositions, setJobPositions] = useState<JobPosition[]>(profile?.jobPositions ?? [])
  const [showModal, setShowModal] = useState(false)
  const [, dispatchLoadingState] = useLoadingState()
  const [doneDisabled, setDoneDisabled] = useState(false)

  const shouldUseDragHandle = useIsMobileDevice()

  const initialItemMod: ItemModProp<JobPosition> = {
    item: {
      title: ``,
      companyName: ``,
      dateRange: {
        monthFrom: 0,
        yearFrom: currentYear,
        toPresent: true
      }
    },
    onOk: async (item, index, cleanups = []) => {
      try {
        setShowModal(false)
        const newJobPositions = await addItemToArray(jobPositions)(item, index)
        const updateFunc = async () => await updateJobPositions(newJobPositions, jobPositions)
        await updateWrapper(dispatchLoadingState, updateFunc)
        setJobPositions(newJobPositions)
        Promise.all(cleanups.map(async (c) => await c()))
      }
      catch (err) {
        console.error(err)
        message.error(`Something went wrong...`)
      }
    },
    onCancel: async () => { setShowModal(false) },
    mode: `add`,
    index: undefined
  }

  const [itemMod, setItemMod] = useState(initialItemMod)

  const onSortEnd = (props: { oldIndex: number, newIndex: number }) => {
    setJobPositions(arrayMove(jobPositions, props.oldIndex, props.newIndex))
  };

  return (<div className={styles.root} id={id}>
    <div className={`card ${styles.cardBody}`}>
      <div className="card__header margin--auto">
        <h3 style={{ fontSize: 36, marginTop: 0, marginBottom: 0 }}>Experience</h3>
      </div>
      {
        jobPositions.length ?
          <div className={`${styles.innerCardBody}`}>
            {
              function () {
                const SortableJobPosContainer = SortableContainer(() => {
                  return <ul style={{ padding: 0 }}>
                    {jobPositions.map((p, i) => {
                      const SortableJobPos = SortableElement(() => {
                        const { title, url, dateRange, location, description, companyLogo, companyName } = p
                        const displayedDateRange = dateRangeDisplay(dateRange)
                        const companyLogoFull = companyLogo ?? defaultInsititution
                        return (
                          <div key={i} className={`card__body ${styles.experienceElem}`}>
                            <hr style={{ marginTop: 12 }} />
                            {shouldUseDragHandle && mode === 'edit' && <DragHandle></DragHandle>}
                            {
                              <div className={`avatar avatar--vertical ${styles.avatarMobileLogo} margin--auto`}>
                                {url ?
                                  <a href={url} target="_blank" rel="noopener noreferrer" >
                                    <img className={`hoverLarge margin--auto`} src={companyLogoFull} alt={companyName} />
                                  </a>
                                  :
                                  <img src={companyLogoFull} alt={companyName} className={`margin--auto`} />
                                }
                              </div>
                            }
                            <div className="avatar">
                              {
                                <div className={`avatar ${styles.avatarLogo}`}>
                                  {url ?
                                    <a href={url} target="_blank" rel="noopener noreferrer" style={{ maxWidth: '80%', maxHeight: '80%', margin: 'auto' }} >
                                      <img className="hoverLarge" src={companyLogoFull} alt={companyName} />
                                    </a>
                                    :
                                    <img src={companyLogoFull} alt={companyName} style={{ maxHeight: '80%', maxWidth: '80%', margin: 'auto' }} />
                                  }
                                </div>
                              }
                              <div className={`avatar__intro ${styles.avatarContent}`} style={{ marginTop: 0 }}>
                                <h4 className="mobile-center-full-left avatar__name" style={{ fontSize: 20, marginBottom: 4 }}>{title}</h4>
                                <div className={styles.centerOnMobile}>
                                  {
                                    joinStringsWithDot([
                                      companyName,
                                      displayedDateRange,
                                      location
                                    ])
                                  }
                                </div>
                                <div className={styles.jobContent}>
                                  <InnerHTML html={replaceNewlinesWithBr(description ?? "")} />
                                </div>
                              </div>
                            </div>
                            {
                              mode === `edit` &&
                              <IndivEditButtons itemName={itemName} editAction={async () => {
                                const newItemMod: ItemModProp<JobPosition> = {
                                  ...initialItemMod,
                                  item: p,
                                  mode: `edit`,
                                  index: i,
                                  onDelete: async (cleanups = []) => {
                                    try {
                                      setShowModal(false)
                                      const newJobPositions = await deleteItemFromArray(i, jobPositions)()
                                      const updateFunc = async () => await updateJobPositions(newJobPositions, jobPositions)
                                      await updateWrapper(dispatchLoadingState, updateFunc)
                                      setJobPositions(newJobPositions)
                                      Promise.all(cleanups.map(async (c) => await c()))
                                    }
                                    catch (err) {
                                      console.error(err)
                                      message.error(`Something went wrong...`)
                                    }
                                  }
                                }
                                setItemMod(newItemMod)
                                setShowModal(true)

                              }} />
                            }
                          </div>)
                      }) // const SortableJobPos
                      return <SortableJobPos disabled={mode !== `edit`} key={`${i}-sortable`} index={i} ></SortableJobPos>
                    })
                    }
                  </ul>
                })
                return <SortableJobPosContainer useWindowAsScrollContainer useDragHandle={shouldUseDragHandle} distance={2} axis="y" onSortEnd={onSortEnd}></SortableJobPosContainer>
              }()
            }
            {jobPositions.length > 1 && mode === `edit` && <p>
              {
                shouldUseDragHandle
                  ?
                  `Drag an ${itemName} item with the drag handle to change the order`
                  :
                  `Drag an ${itemName} item to change the order`
              }
            </p>}

          </div>
          :
          <div className={`card__body ${styles.innerCardBody}`}>
            <hr />
            <p>Add some {pluralItemName} and it will appear here!</p>
          </div>
      }


      {
        mode === `view` &&
        <MainEditButton pluralItemName={pluralItemName} targetUrl={`/${endpoints.profile}/edit/experience`} />
      }
      {
        mode === `edit` &&
        <div>
          <AddNewButton itemName={itemName} action={async () => {
            if (jobPositions.length < 10) {
              setItemMod(initialItemMod)
              setShowModal(true)
            }
            else {
              message.error(`You can only add up to 10 ${pluralItemName}`)
            }
          }} />
          <div>
            <DoneButton disabled={doneDisabled} action={async () => {
              /// save the ordering
              setDoneDisabled(true)
              try {
                const updateFunc = async () => await updateJobPositions(jobPositions, profile?.jobPositions ?? [])
                await updateWrapper(dispatchLoadingState, updateFunc)
                history.push(`/${endpoints.profile}`)
              }
              catch (err) {
                console.error(err)
                message.error(`Something went wrong...`)
              }
              setDoneDisabled(false)
            }} />
          </div>
        </div>
      }
    </div>
    <AddEditDeleteModal itemModProp={itemMod} show={showModal} uid={state.firebaseCurrentUserId ?? `` /* the fallback should never happen */} />
  </div>)
}

JobPositions.defaultProps = defaultProfileComponentProps
