import React, { useState } from 'react'
import firebase from 'firebase'
import { isEqual } from 'lodash'
import { firebaseConsts } from '../consts';
import { userDocFirestoreToUserProfile, userDocFirestoreToUserPreferences } from '../utils/firebase/shim';
import { firestore } from '../utils/firebase/firebase';
import { useLoadingState } from './loadingState';

const { useEffect, createContext, useReducer, useContext } = React;

type ProfileStateDispatchType = (values: ProfileState) => void;

const localStorageProfileState = window.localStorage.getItem(`profileState`)
const initialProfileState: ProfileState = localStorageProfileState ? JSON.parse(localStorageProfileState) : {}

/// we cannot trust this initial state to be ready, we need to wait for the listener to call back
/// to set isFirebaseAuthReady
initialProfileState.isFirebaseAuthReady = false

const ProfileStateContext = createContext(initialProfileState)
const DispatchProfileStateContext = createContext({} as ProfileStateDispatchType)

const writeProfileStateToLocalStorage = async (item: ProfileState) => {
    const str = JSON.stringify(item)
    window.localStorage.setItem(`profileState`, str)
}

export const ProfileStateProvider = ({ children }: { children: any }) => {
    const [, dispatchLoadingState] = useLoadingState()
    const [initialPageLoad, setInitialPageLoad] = useState(true)
    const [state, dispatch] = useReducer(
        (state: ProfileState, newValue: ProfileState) => {
            const nextState: ProfileState = { ...state, ...newValue }
            if (!isEqual(state, nextState)) {
                writeProfileStateToLocalStorage(nextState)
            }
            return nextState
        },
        initialProfileState
    )
    const [unsub, setUnsub] = useState<(() => void) | undefined>(undefined)
    const [currUid, setCurrUid] = useState<string | undefined>(undefined)

    const sub = async (uid: string) => {
        const listener = firestore.collection(firebaseConsts.usersCollection).doc(uid)
            .onSnapshot(async (doc) => {

                if (doc.exists) {
                    const data = doc.data()
                    const userDocFirestore = data as UserDocFirestore
                    const userProfile = await userDocFirestoreToUserProfile(userDocFirestore)
                    const userPreferences = userDocFirestoreToUserPreferences(userDocFirestore)

                    dispatch({
                        firebaseCurrentUserId: uid,
                        profile: userProfile,
                        userDocFirestore,
                        isFirebaseAuthReady: true,
                        userPreferences,
                    })
                    if (initialPageLoad) {
                        setInitialPageLoad(false)
                        dispatchLoadingState({ loading: false })
                    }

                }
                else {
                    console.warn(`Gotten snapshot data is empty`)
                    /// this might occur at first login becauser the snapshot will fail, login is too fast
                }
            }
                , (error) => {
                    console.error(error)
                    console.log(`something went wrong in listener`)
                }
            )
        setUnsub(() => listener)
    }

    useEffect(() => {
        /// if localstorage is empty we don't set it to loading, but if so, it means a login was cached
        /// we set it to loading
        if (localStorageProfileState) {
            dispatchLoadingState({ loading: true })
        }


        firebase.auth().onAuthStateChanged((user) => {
            console.log("auth state change user: ", user?.uid)
            /// we need to get the first one 
            const uid = user?.uid

            if (!uid && unsub) {
                unsub()
                setUnsub(undefined)
            }
            else if (uid && uid !== currUid) {
                setCurrUid(uid)
                sub(uid)
            }
            return () => {
                if (unsub) {
                    unsub()
                    setUnsub(undefined)
                }
            }
        });
    }, [])

    return (
        <ProfileStateContext.Provider value={state} >
            <DispatchProfileStateContext.Provider value={dispatch}>
                {children}
            </DispatchProfileStateContext.Provider>
        </ProfileStateContext.Provider>
    )
}
export const useProfileState = (): [ProfileState, ProfileStateDispatchType] => {
    return [
        useContext(ProfileStateContext),
        useContext(DispatchProfileStateContext)
    ]
}
