import React, { useState } from 'react'
import { ProfileComponentProps, defaultProfileComponentProps, ItemModProp, addItemToArray, deleteItemFromArray } from '../common'
import styles from '../common.module.css'
import { MainEditButton, AddNewButton, IndivEditButtons, DoneButton } from '../Subcomponents/EditButtons'
import { message } from 'antd'
import { dateRangeDisplay } from '../../../../utils/datetime'
import { useProfileState } from '../../../../contexts/profileState'
import { updateProjects, updateWrapper } from '../../../../utils/firebase/profile'
import { useLoadingState } from '../../../../contexts/loadingState'
import { currentYear, endpoints } from '../../../../consts'
import { history } from '../../../../utils/history'
import { replaceNewlinesWithBr, capitalisePhrase } from '../../../../utils/utils';
import { AddEditDeleteModal } from './Form'
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import ReactPlayer from "react-player"
import arrayMove from 'array-move';
import useIsMobileDevice from '../../../../utils/useIsMobileDevice';
import DragHandle from '../../../../components/DragHandle/DragHandle';
import InnerHTML from 'dangerously-set-html-content'

export const itemName = `project`
export const pluralItemName = `projects`
export const id = `projects`

// const testProjs: Project[] = [
//   {
//     title: `Portosaurus`,
//     monthYearFrom: `Jan 2020`,
//     monthYearTo: `Jun 2020`,
//     description: [
//       `Portosaurus is a nice thing`,
//       `Lorem ipsum dolor sit amet, consectetur adipisicing elit.`,
//       `Iusto cupiditate sit fugiat, exercitationem vitae nisi consequuntur molestiae, explicabo, dicta et autem accusamus facere atque nostrum rerum doloribus neque ea earum!`
//     ],
//     url: `https://www.portosaurus.com`
//   }, {
//     title: `Frontboi`,
//     monthYearFrom: `Jan 2020`,
//     monthYearTo: `Jun 2020`,
//   }
// ]



const ProjectCard = (props: {
  proj: Project, offset: number, parentProps: ProfileComponentProps, onEdit: () => Promise<void>, shouldUseDragHandle: boolean
}) => {
  const { proj, offset, parentProps, onEdit, shouldUseDragHandle } = props
  const { mode } = parentProps
  const renderDescription: boolean = proj.description !== undefined && proj.description.length > 0

  const displayedDateRange = dateRangeDisplay(proj.dateRange)
  return (

    <div className={`col col--4 ${offset && `col--offset-${offset}`}`} style={{ marginBottom: `1.5rem`, color: `white`, textAlign: `center` }}>

      <div className="card" style={{ backgroundColor: `var(--ifm-color-gray-800)`, height: `100%` }}>
        <div className="card__header">
          {shouldUseDragHandle && mode === 'edit' && <DragHandle></DragHandle>}

          <h3 style={{ marginBottom: 4 }}>{proj.title}</h3>
          <h4 style={{ margin: 0, fontWeight: 300 }}>{displayedDateRange}</h4>
        </div>
        {
          // support EITHER img or video, priority video
          (proj.projectImg || proj.projectVidUrl) &&
          <div className="card__image">
            {
              proj.projectVidUrl ?
                <h1 className="aspect-ratio-box-16-9" style={{ marginBottom: 0 }}>
                  <ReactPlayer
                    className="aspect-ratio-box-inside-16-9"
                    width="100%"
                    height="100%"
                    controls={true}
                    title={proj.title}
                    url={proj.projectVidUrl} />
                </h1>
                :
                <img
                    src={proj.projectImg}
                    alt={proj.title}
                    title={proj.title}
                    style={{ verticalAlign: `middle` }}
                />
            }
          </div>
        }
        {
          renderDescription &&
          <div className="card__body" style={{ textAlign: "justify", paddingTop: 12, fontSize: `0.9rem` }}>
            <InnerHTML html={replaceNewlinesWithBr(proj.description ?? "")} />
          </div>
        }
        {
          proj.url &&
          <div className="card__footer">
            <a href={proj.url} target="_blank" rel="noopener noreferrer" >
              <button className="button button--secondary button--block">See Project</button>
            </a>
          </div>
        }
        {
          mode === `edit` &&
          <div style={{ marginBottom: 12, marginTop: 6 }}>
            <IndivEditButtons itemName={itemName} editAction={onEdit} />
          </div>
        }
      </div>
    </div>
  )
}
export const Projects = (props: ProfileComponentProps) => {
  const { mode } = props
  const [state,] = useProfileState()
  const { profile } = state
  const [projects, setProjects] = useState<Project[]>(profile?.projects ?? [])
  const [showModal, setShowModal] = useState(false)
  const [, dispatchLoadingState] = useLoadingState()
  const [doneDisabled, setDoneDisabled] = useState(false)

  const initialItemMod: ItemModProp<Project> = {
    item: {
      title: ``,
      dateRange: {
        monthFrom: 0,
        yearFrom: currentYear,
        toPresent: true
      }
    },
    onOk: async (item, index, cleanups = []) => {
      try {
        setShowModal(false)
        const newProjects = await addItemToArray(projects)(item, index)
        const updateFunc = async () => await updateProjects(newProjects, projects)
        await updateWrapper(dispatchLoadingState, updateFunc)
        setProjects(newProjects)
        Promise.all(cleanups.map(async (c) => await c()))
      }
      catch (err) {
        console.error(err)
        message.error(`Something went wrong...`)
      }
    },
    onCancel: async () => { setShowModal(false) },
    mode: `add`,
    index: undefined
  }

  const [itemMod, setItemMod] = useState(initialItemMod)
  const shouldUseDragHandle = useIsMobileDevice()

  const onSortEnd = (props: { oldIndex: number, newIndex: number }) => {
    setProjects(arrayMove(projects, props.oldIndex, props.newIndex))
  };

  const getProjects = (projects: Project[]) => {
    const noOfProjects = projects.length

    /// offset the last row's first element if required
    const offsetIndex: number | undefined = (noOfProjects % 3 === 0) ? undefined : (noOfProjects - (noOfProjects % 3))
    const offsetAmount: number | undefined = (noOfProjects % 3 === 0) ? undefined : (3 - (noOfProjects % 3)) * 2

    return projects.map((p, i) => {
      return (
        <>
          <ProjectCard
            shouldUseDragHandle={shouldUseDragHandle}
            parentProps={props}
            key={i}
            proj={p}
            offset={i === offsetIndex && offsetAmount ? offsetAmount : 0}
            onEdit={
              async () => {
                const newItemMod: ItemModProp<Project> = {
                  ...initialItemMod,
                  item: p,
                  mode: `edit`,
                  index: i,
                  onDelete: async (cleanups = []) => {
                    try {
                      setShowModal(false)
                      const newProjects = await deleteItemFromArray(i, projects)()
                      const updateFunc = async () => await updateProjects(newProjects, projects)
                      await updateWrapper(dispatchLoadingState, updateFunc)
                      setProjects(newProjects)
                      Promise.all(cleanups.map(async (c) => await c()))
                    }
                    catch (err) {
                      console.error(err)
                      message.error(`Something went wrong...`)
                    }
                  },
                  onCancel: async () => { setShowModal(false) },
                }
                setItemMod(newItemMod)
                setShowModal(true)
              }
            }
          />
        </>
      )
    })
  }



  return (<div className={styles.root} id={id}>
    <div className={`card ${styles.cardBody}`}>
      <div className="card__header margin--auto">
        <h3 style={{ fontSize: 36, marginTop: 0, marginBottom: 0 }}>{capitalisePhrase(pluralItemName)}</h3>
      </div>
      <div className={`card__body ${styles.innerCardBody}`} style={{ width: `95%` }}>
        <hr />
        {
          projects.length ?
            <div>
              {
                function () {
                  const SortableProjectContainer = SortableContainer(() => {
                    return <ul className="row" style={{ padding: 0 }}>
                      {getProjects(projects).map((proj, i) => {
                        const SortableProj = SortableElement(() => proj)
                        return <SortableProj disabled={mode !== `edit`} key={`${i}-sortable`} index={i} ></SortableProj>
                      })}
                    </ul>
                  })
                  return <SortableProjectContainer useWindowAsScrollContainer useDragHandle={shouldUseDragHandle} distance={2} axis="xy" onSortEnd={onSortEnd} />
                }()
              }
              {projects.length > 1 && mode === `edit` && <p>
                {
                  shouldUseDragHandle
                    ?
                    `Drag a ${itemName} with the drag handle to change the order`
                    :
                    `Drag a ${itemName} to change the order`
                }
              </p>}
            </div>
            :
            <div>
              <p>Add some {pluralItemName} and they will appear here!</p>
            </div>
        }
      </div>




      {
        mode === `view` &&
        <MainEditButton pluralItemName={pluralItemName} targetUrl={`/${endpoints.profile}/edit/projects`} />
      }
      {
        mode === `edit` &&
        <div>
          <AddNewButton itemName={itemName} action={async () => {
            if (projects.length < 50) {
              setItemMod(initialItemMod)
              setShowModal(true)
            }
            else {
              message.error(`You can only add up to 50 ${pluralItemName}`)
            }
          }} />
          <div>
            <DoneButton disabled={doneDisabled} action={async () => {
              /// save the ordering
              setDoneDisabled(true)
              try {
                const updateFunc = async () => await updateProjects(projects, profile?.projects ?? [])
                await updateWrapper(dispatchLoadingState, updateFunc)
                history.push(`/${endpoints.profile}`)
              }
              catch (err) {
                console.error(err)
                message.error(`Something went wrong...`)
              }
              setDoneDisabled(false)
            }} />
          </div>
        </div>
      }
    </div>
    <AddEditDeleteModal itemModProp={itemMod} show={showModal} uid={state.firebaseCurrentUserId ?? `` /* the fallback should never happen */} />
  </div >)
}

Projects.defaultProps = defaultProfileComponentProps
