import { useEffect, useState } from "react"
import { mobileWidth } from '../consts';

//checks if screen width is thin enough to be mobile, and sets the state.
function useIsMobileDevice():
  boolean {
  const [isMobile, setIsMobile] = useState(window.innerWidth < mobileWidth)
  useEffect(() => {
    window.addEventListener('resize', () => {
      setIsMobile(window.innerWidth < mobileWidth)
    })
  }, [])
  return isMobile
}

export default useIsMobileDevice